import React from 'react';
import { take } from 'lodash';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import {
  AvatarGroup,
  AvatarGroupSize,
  AvatarSize,
  Tabs,
  TabsAlignment,
  TabsVariant,
  Text,
} from 'wix-ui-tpa';

import { IReactionsSummary } from 'api/feed/types';
import { IGroupMember } from 'api/members/types';
import { Role as GroupRole } from '@wix/ambassador-social-groups-v2-group-role/types';

import { UserCard } from 'common/components/Cards/UserCard';
import { Modal, ModalSkin } from 'common/components/Modal';
import { AdminBadge } from 'common/components/AdminBadge';

import settingsParams from 'Group/settingsParams';

import type { IReactionsMap } from '../selectors';

import { ReactedMembersTooltip } from './ReactedMembersTooltip';
import { REACTED_MEMBER, REACTIONS } from './dataHook';

import { classes, st } from './ReactedMembers.st.css';
import { getReactionIcon, toReactionType } from '../../SocialBar/helpers';

interface ReactedMembersProps {
  legacy?: boolean;
  reactionsMap: IReactionsMap;
  reactions: IReactionsSummary;
  users: IGroupMember[];
}

export const ReactedMembers: React.FC<ReactedMembersProps> = ({
  legacy = true,
  reactions,
  reactionsMap,
  users,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const settings = useSettings();

  const showBadges = settings.get(settingsParams.showMembersBadges);

  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const profiles = React.useMemo(() => {
    return take(users, 20).map((user) => {
      const { name, imageUrl } = user;
      return {
        name: name || t('groups-web.member.anonymous'),
        src: imageUrl,
      };
    });
  }, [users]);

  const tabs = [getTotalTab(), ...getSummaryTabs()];

  const { reactions: activeTabReactions } = tabs[activeTabIndex] || {
    reactions: [],
  };

  return (
    <div
      className={st(classes.root, { mobile: isMobile })}
      data-hook={REACTIONS}
    >
      <ReactedMembersTooltip
        content={users
          .slice(0, 20)
          .map(
            (member: IGroupMember) =>
              member.name || t('groups-web.member.anonymous'),
          )
          .join(', ')}
      >
        <AvatarGroup
          maxAmount={4}
          items={profiles}
          size={AvatarGroupSize.xSmall}
          className={classes.total}
        >
          <AvatarGroup.TextButton onClick={openModal}>
            {t('groups-web.discussion.feed.reactions.total_icu', {
              count: reactions.total,
            })}
          </AvatarGroup.TextButton>
        </AvatarGroup>
      </ReactedMembersTooltip>
      <Modal
        isOpen={isModalOpened}
        onClose={closeModal}
        skin={ModalSkin.REACTIONS}
      >
        <Tabs
          activeTabIndex={activeTabIndex}
          alignment={TabsAlignment.left}
          variant={TabsVariant.fit}
          onTabClick={handleTabClick}
          items={tabs}
          className={classes.tabs}
        />
        <SwitchTransition>
          <CSSTransition
            key={activeTabIndex}
            timeout={300}
            classNames={classes as CSSTransitionClassNames}
          >
            <ul className={classes.members}>
              {activeTabReactions.map((reaction) => (
                <li
                  className={classes.member}
                  key={reaction.user.siteMemberId + reaction.code}
                >
                  <UserCard
                    bw
                    showBadges={showBadges}
                    profile={reaction.user}
                    avatarSize={AvatarSize.medium}
                    action={
                      <span className={classes.emoji}>
                        {getIcon(reaction.code)}
                      </span>
                    }
                    subtitle={
                      reaction.user.role === GroupRole.ADMIN && <AdminBadge />
                    }
                    dataHook={REACTED_MEMBER}
                  />
                </li>
              ))}
            </ul>
          </CSSTransition>
        </SwitchTransition>
      </Modal>
    </div>
  );

  function openModal() {
    setIsModalOpened(true);
  }

  function closeModal() {
    setIsModalOpened(false);
  }

  function handleTabClick(index: number) {
    setActiveTabIndex(index);
  }

  function getIcon(code: string) {
    return legacy ? code : getReactionIcon(toReactionType(code)!) || code;
  }

  function getTotalTab() {
    return {
      reactions: reactions.reactions,
      title: (
        <Text className={`${classes.emoji} ${classes.totalEmoji}`}>
          {t('groups-web.discussion.feed.reactions.all', {
            count: reactions.total,
          })}
        </Text>
      ) as unknown as string,
    };
  }

  function getSummaryTabs() {
    return Object.keys(reactionsMap).map((code) => {
      const reactions = reactionsMap[code];

      return {
        code,
        reactions,
        title: (
          <div className={classes.emoji}>
            <span>{getIcon(code)}</span>
            <span className={classes.usersCount}>
              &nbsp;
              {reactions.length}
            </span>
          </div>
        ) as unknown as string,
      };
    });
  }
};
