import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { IconToggleLabelPlacement } from 'wix-ui-tpa';
import { useSelector } from 'react-redux';
import { debounce, omit } from 'lodash';

import { selectCurrentUser } from 'store/application/selectors';
import { ThumbUpIcon } from 'common/components/Icons/ThumbUpIcon';

import { ReactionsPicker } from './ReactionsPicker';
import { Reaction } from './Reaction';

import type { IReactionsMap } from '../selectors';

import { classes } from './Reactions.st.css';

export interface ReactionsProps {
  reactions: IReactionsMap;

  onReact(code: string): void;
  onUnreact(code: string): void;
}

export const Reactions: React.FC<ReactionsProps> = ({
  reactions,
  onReact,
  onUnreact,
}) => {
  const currentUser = useSelector(selectCurrentUser);

  const handleReaction = debounce(_handleReaction, 300, {
    leading: true,
    trailing: false,
  });

  const likeSummary = reactions['👍'] || [];
  const reactionsSummary = omit(reactions, '👍');

  const liked = didReact('👍');

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        <TransitionGroup>
          <li className={classes.item}>
            <Reaction
              likeButton
              checked={liked}
              onClick={() => handleReaction('👍')}
              labelPlacement={IconToggleLabelPlacement.END}
              icon={!liked ? <ThumbUpIcon /> : '👍'}
              label={likeSummary.length.toString()}
              reactions={likeSummary}
            />
          </li>
          {Object.keys(reactionsSummary).map((code) => {
            const userReactions = reactionsSummary[code];

            return (
              <CSSTransition
                key={code}
                timeout={200}
                classNames={classes as CSSTransitionClassNames}
              >
                <li className={classes.item}>
                  <Reaction
                    className="emoji-mart-emoji-native"
                    icon={code}
                    onClick={() => handleReaction(code)}
                    checked={didReact(code)}
                    labelPlacement={IconToggleLabelPlacement.END}
                    label={userReactions.length}
                    reactions={userReactions}
                  />
                </li>
              </CSSTransition>
            );
          })}
          <li className={classes.item}>
            <ReactionsPicker onSelect={handleReaction} />
          </li>
        </TransitionGroup>
      </ul>
    </div>
  );

  function didReact(code: string) {
    return reactions[code]?.some(
      (reaction) => currentUser.siteMemberId === reaction.user.siteMemberId,
    );
  }

  function _handleReaction(code: string) {
    didReact(code) ? onUnreact(code) : onReact(code);
  }
};
