import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import { selectAnswersStatus } from 'store/members/selectors';
import type { IGroupMember } from 'store/members/types';

import { useController } from 'common/context/controller';

import {
  GroupQuestion,
  GroupQuestionSkeleton,
} from 'Group/Widget/GroupMembership/dialogs/GroupQuestionsDialog/GroupQuestion';

import { classes, st } from './MemberAnswers.st.css';

interface IMemberAnswersProps {
  groupId: string;
  member: IGroupMember;
}

export function MemberAnswers(props: IMemberAnswersProps) {
  const { groupId, member } = props;

  const { members$ } = useController();
  const status = useSelector(
    selectAnswersStatus(member.siteMemberId as string),
  );

  useEffect(() => {
    if (!member.answers?.length) {
      members$.fetchAnswers(groupId, member.siteMemberId as string);
    }
  }, [member.siteMemberId, groupId]);

  return (
    <SwitchTransition>
      <CSSTransition
        key={String(status.loading)}
        unmountOnExit
        classNames={classes as CSSTransitionClassNames}
        timeout={300}
      >
        {status.loading ? (
          <ul className={classes.root}>
            <li className={classes.answer}>
              <GroupQuestionSkeleton />
            </li>
            <li className={classes.answer}>
              <GroupQuestionSkeleton />
            </li>
            <li className={classes.answer}>
              <GroupQuestionSkeleton />
            </li>
          </ul>
        ) : (
          <ul className={classes.root}>
            {member.answers?.map((answer, index) => (
              <li key={answer.id} className={classes.answer}>
                <GroupQuestion
                  disabled
                  index={index + 1}
                  question={answer.question}
                  required={answer.required}
                  value={answer.answer}
                />
              </li>
            ))}
          </ul>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
}
