import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import {
  TransitionGroup,
  SwitchTransition,
  CSSTransition,
} from 'react-transition-group';
import {
  Divider,
  TPAComponentsConfig,
  DialogProps,
  Spinner,
  ButtonPriority,
} from 'wix-ui-tpa';

import type { IGroupMember } from 'store/members/types';

import { ModalV2 } from 'common/components/Modal/ModalV2';
import { Button } from 'common/components/Button';

import { MemberAnswers } from './MemberAnswers';

interface IMemberAnswersDialogProps {
  isOpen: boolean;
  groupId: string;
  member: IGroupMember;

  onClose(): void;
}

export function MemberAnswersDialog(props: IMemberAnswersDialogProps) {
  const { groupId, member, isOpen, onClose } = props;
  const { t } = useTranslation();

  return (
    <ModalV2 isOpen={isOpen} onClose={onClose}>
      <ModalV2.Title>
        {t('groups-web.membership-questions.answers-modal.title', {
          userName: member.name,
        })}
      </ModalV2.Title>
      <ModalV2.Content>
        <MemberAnswers groupId={groupId} member={member} />
      </ModalV2.Content>
      <ModalV2.Footer
        okButton={
          <Button onClick={onClose} priority={ButtonPriority.primary}>
            {t('groups-web.close')}
          </Button>
        }
      />
    </ModalV2>
  );
}

MemberAnswersDialog.displayName = 'MemberAnswersDialog';
