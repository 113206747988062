import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';

import { ButtonPriority, Spinner } from 'wix-ui-tpa';

import type { IGroupMember } from 'api/members/types';
import { selectRemoveStatus } from 'store/members/selectors';

import { useDidUpdate } from 'common/hooks';

import { useController } from 'common/context/controller';
import { ModalProps } from 'common/components/Modal/Modal';
import {
  Dialog,
  DATA_HOOKS as DIALOG_DATA_HOOKS,
} from 'common/components/Dialog';
import { Button } from 'common/components/Button';
import { memberTabAdminActionDone } from '@wix/bi-logger-groups/v2';
import { BIUserEntry } from 'common/bi-logger/types';

export interface IRemoveMemberDialogProps extends ModalProps {
  isOpen: boolean;
  groupId: string;
  member: IGroupMember;

  onClose(): void;
}

export function RemoveMemberDialog(props: IRemoveMemberDialogProps) {
  const { isOpen, member, groupId, onClose } = props;
  const { t } = useTranslation();
  const bi = useBi();

  const { members$ } = useController();
  const status = useSelector(selectRemoveStatus(member.siteMemberId as string));

  useDidUpdate(() => {
    if (!status.loading && !status.error) {
      onClose();
    }
  }, [status.loading, status.error]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={t('groups-web.group.actions.remove-member.modal.title')}
      caption={t('groups-web.group.actions.remove-member.modal.caption', {
        memberFullName: member.name,
      })}
      buttons={
        <>
          <Button bw onClick={onClose} priority={ButtonPriority.secondary}>
            {t('groups-web.cancel')}
          </Button>
          <Button
            bw
            onClick={handleSubmit}
            disabled={status.loading}
            priority={ButtonPriority.primary}
            data-hook={DIALOG_DATA_HOOKS.okButton}
          >
            {status.loading ? (
              <Spinner diameter={20} />
            ) : (
              t('groups-web.group.actions.remove-member.modal.confirm')
            )}
          </Button>
        </>
      }
    />
  );

  function handleSubmit() {
    members$.remove(groupId, member.siteMemberId as string);
    bi.report(
      memberTabAdminActionDone({
        action: 'remove_member',
        group_id: groupId,
        origin: 'dialog_screen_btn_clk',
        userEntry: BIUserEntry.SITE,
        site_member_id: member.siteMemberId as string,
      }),
    );
  }
}

RemoveMemberDialog.displayName = 'RemoveMemberDialog';
