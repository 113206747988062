import { createEntityAdapter } from '@reduxjs/toolkit';

import { IFeedItem } from 'store/feed/types';

import { IRootState } from '../types';

export const feedItemsAdapter = createEntityAdapter<IFeedItem>({
  selectId: (item) => item.feedItemId as string,
  sortComparer: (a, b) => {
    if (a.pin) {
      return -1;
    }
    if (b.pin) {
      return 1;
    }

    if (a.createdAt! > b.createdAt!) {
      return -1;
    }

    if (a.createdAt! < b.createdAt!) {
      return 1;
    }

    return 0;
  },
});

export const selectors = feedItemsAdapter.getSelectors<IRootState>(
  (state) => state.feed,
);
