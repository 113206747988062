import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ButtonPriority } from 'wix-ui-tpa';

import { selectDialog } from 'store/application/selectors';
import { selectGroupRequirements } from 'store/groups/selectors';

import { Modal } from 'common/components/Modal';
import { useController } from 'common/context/controller';
import { BlackAndWhiteButton } from 'common/components/BlackAndWhiteButton';
import { useRouter } from 'common/router';

import { usePricingPlansQueryParams } from './usePricingPlansQueryParams';
import { usePaidPlansBi } from './bi';

export const PricingPlansRestrictionDialog: React.FC = () => {
  const { t } = useTranslation();

  const router = useRouter();

  const { application$ } = useController();

  const bi = usePaidPlansBi();

  const dialog = useSelector(selectDialog('paidPlansRestriction'));
  const requirements = useSelector(
    selectGroupRequirements(dialog.groupId as string),
  );

  const queryParams = usePricingPlansQueryParams(dialog.groupId as string);

  return (
    <Modal isOpen={dialog.isOpen} onClose={handleClose}>
      <Modal.Header
        title={t('groups-web.restriction.plans.dialog.title')}
        subtitle={t('groups-web.restriction.plans.dialog.subtitle')}
      />
      <Modal.Buttons>
        <BlackAndWhiteButton
          priority={ButtonPriority.basic}
          onClick={() => {
            if (
              requirements?.violation?.pricingPlansOptions &&
              !requirements.violation.pricingPlansOptions.installed
            ) {
              return;
            }
            bi.getPlanClick();
            router.go('pricing-plans', {
              appSectionParams: queryParams,
            });
          }}
        >
          {t('groups-web.restriction.plans.dialog.cta')}
        </BlackAndWhiteButton>
      </Modal.Buttons>
    </Modal>
  );

  function handleClose() {
    application$.closeDialog('paidPlansRestriction');
  }
};
