import React from 'react';

export const LinkedIn: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <path
          id="LinkedIn"
          d="M20.452 20.45h-3.558v-5.57c0-1.328-.024-3.036-1.85-3.036-1.852 0-2.135 1.448-2.135 2.941v5.666H9.35V8.997h3.414v1.567h.049c.475-.901 1.636-1.85 3.368-1.85 3.605 0 4.27 2.37 4.27 5.455v6.282zM5.338 7.433A2.063 2.063 0 1 1 7.4 5.368a2.065 2.065 0 0 1-2.063 2.064zM3.557 20.451h3.561V8.997H3.557v11.454zM22.225 0H1.77C.794 0 0 .775 0 1.73v20.538C0 23.224.794 24 1.771 24h20.454c.979 0 1.775-.776 1.775-1.732V1.731C24 .775 23.204 0 22.225 0z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 -.031)">
        <mask id="LinkedIn1" fill="#fff">
          <use xlinkHref="#LinkedIn" />
        </mask>
        <use fill="#1A1919" xlinkHref="#LinkedIn" />
        <path fill="#000" d="M0 0h24v24H0z" mask="url(#LinkedIn1)" />
      </g>
    </svg>
  );
};
