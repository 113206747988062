import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import Picker from 'emoji-mart/dist-es/components/picker/picker';
import { BaseEmoji } from 'emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index';

import './EmojiPicker.global.css';
import { classes, st } from './EmojiPicker.st.css';

interface EmojiPickerProps {
  onSelect(reactionCode: string): void;
}

export const EmojiPicker: React.FC<EmojiPickerProps> = ({ onSelect }) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const prefix = (key: string) =>
    t(`groups-web.discussion.feed.reactions.picker.${key}`);

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <Picker
        native
        color={null as any}
        showSkinTones={false}
        showPreview={false}
        onSelect={handleEmojiSelect}
        i18n={{
          search: prefix('search'),
          notfound: prefix('notfound'),
          categories: {
            search: prefix('categories.search'),
            recent: prefix('categories.recent'),
            people: prefix('categories.people'),
            nature: prefix('categories.nature'),
            foods: prefix('categories.foods'),
            activity: prefix('categories.activity'),
            places: prefix('categories.places'),
            objects: prefix('categories.objects'),
            symbols: prefix('categories.symbols'),
            flags: prefix('categories.flags'),
            custom: prefix('categories.custom'),
          },
        }}
      />
    </div>
  );

  function handleEmojiSelect(emoji: BaseEmoji) {
    const { native: reactionCode } = emoji;
    onSelect(reactionCode);
  }
};
