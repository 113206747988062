import { useMemo } from 'react';
import { useStyles } from '@wix/tpa-settings/react';
import { getWixColor } from '@wix/tpa-settings';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { RicosTheme } from '@wix/ricos-common/dist/types';

import { useTheme } from 'common/context/theme';

import stylesParams from '../../stylesParams';

export function useRicosTheme(
  options: {
    bw?: boolean;
    parentClass?: string;
    isEditor?: boolean;
    maxHeight?: string;
  } = {},
) {
  const styles = useStyles();
  const { bw, parentClass, maxHeight } = options;

  const { host } = useTheme();
  const { isMobile } = useEnvironment();

  const postsTextColor = styles.get(stylesParams.postsTextColor);
  const postsBackgroundColor = styles.get(stylesParams.postsBackgroundColor);
  const postsTextFont = styles.get(stylesParams.postsTextFont);
  const mobilePostsTextFontSize = styles.get(
    stylesParams.mobilePostsTextFontSize,
  );

  const blackHex = '#000000';
  const whiteHex = '#ffffff';

  const theme = useMemo<RicosTheme>(() => {
    return {
      parentClass: parentClass || host.id,
      paletteConfig: {
        contentBgColor: false,
      },
      typography: {
        fontFamily: postsTextFont.family,
      },
      palette: {
        textColor: getTextColor(),
        bgColor: getBackgroundColor(),
        actionColor: getColor('color-8'),
        disabledColor: getColor('color-3'),
        fallbackColor: getColor5(),
        textColorLow: getColor5(),
      },
      customStyles: getCustomStyles(),
    };
  }, [
    postsTextColor,
    postsBackgroundColor,
    postsTextFont,
    bw,
    parentClass,
    host.id,
  ]);
  return { theme };

  function getCustomStyles() {
    const fontSize = isMobile ? mobilePostsTextFontSize : postsTextFont.size;

    return {
      p: {
        fontSize: fontSize + 'px',
      },
      link: {
        fontSize: fontSize + 'px',
      },
      ...(maxHeight ? { editor: { maxHeight } } : {}),
    };
  }

  function getTextColor(): string {
    if (bw) {
      return blackHex;
    }
    return postsTextColor.value;
  }

  function getBackgroundColor(): string {
    if (bw) {
      return whiteHex;
    }
    return postsBackgroundColor.value;
  }

  function getColor5(): string {
    if (bw) {
      return blackHex;
    }
    return getColor('color-5');
  }

  function getColor(reference: any) {
    return getWixColor({ colors: host.style.siteColors, reference })?.value;
  }
}
