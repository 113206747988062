import React from 'react';
import type { ReactionType } from 'wix-ui-tpa';

import {
  REACTIONS_MAP,
  REACTION_CODE_MAP,
  REACTION_TYPE_MAP,
} from './constants';

export function fromReactionType(type: ReactionType): string {
  return REACTION_TYPE_MAP[type];
}

export function toReactionType(id: string): ReactionType | undefined {
  return REACTION_CODE_MAP[id];
}

export function getReactionLabel(id: ReactionType) {
  return REACTIONS_MAP[id as keyof typeof REACTIONS_MAP]?.label;
}

export function getReactionIcon(id: ReactionType) {
  const icon = REACTIONS_MAP[id as keyof typeof REACTIONS_MAP]?.icon;

  return icon ? React.createElement(icon) : null;
}
