import React, { useEffect } from 'react';
import { IThemeContextValue } from './Theme';
import { ButtonType } from 'settings/consts';

export const ThemeContext = React.createContext<IThemeContextValue>({
  buttonType: ButtonType.rectangle,
} as IThemeContextValue);

ThemeContext.displayName = 'ThemeContext';

interface IThemeProviderProps extends IThemeContextValue {
  children: React.ReactNode;
}

export function ThemeProvider(props: IThemeProviderProps) {
  const [state, setState] = React.useState<IThemeContextValue>(props);

  useEffect(() => {
    setState(props);
  }, [props.buttonBorderRadius, props.buttonType]);

  return (
    <ThemeContext.Provider value={state}>
      {props.children}
    </ThemeContext.Provider>
  );
}
