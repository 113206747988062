import React from 'react';

import { Badge, BadgePriority } from 'wix-ui-tpa';

import type { IBadge } from 'api/badges/types';

import { MEMBER_BADGES_ITEM, MEMBER_BADGES_LIST } from './dataHook';

import { st, classes } from './MemberBadges.st.css';

interface MemberBadgesProps {
  badges?: IBadge[];
}

export const MemberBadges: React.FC<MemberBadgesProps> = (props) => {
  if (!props.badges?.length) {
    return null;
  }

  return (
    <ul className={st(classes.root)} data-hook={MEMBER_BADGES_LIST}>
      {props.badges.map((badge) => (
        <li key={badge.id} className={classes.badge}>
          <Badge
            priority={BadgePriority.secondary}
            data-hook={MEMBER_BADGES_ITEM}
          >
            {badge.title}
          </Badge>
        </li>
      ))}
    </ul>
  );
};
