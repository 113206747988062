import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  HeroImage,
  ImageResizeOptions,
  ImageLoadingBehaviorOptions,
} from 'wix-ui-tpa';
import { FeedItemActivityType } from 'api/feed/types';
import { IFeedItem } from 'store/feed/types';
import { RichContentViewer } from 'Group/Widget/RichContent/Viewer';
import { classes } from './FeedItem.st.css';

interface FeedItemContentProps {
  item: IFeedItem;

  enablePreview?: boolean;
}

export const FeedItemContent: React.FC<FeedItemContentProps> = ({
  item,
  enablePreview,
}) => {
  const { isMobile } = useEnvironment();

  const groupId = item.applicationContext?.groupId as string;
  const content = React.useMemo(
    () => getFeedItemContent(item),
    [item.entity.body?.content, item.activity?.activityType],
  );

  if (
    item.activity?.activityType === FeedItemActivityType.GROUP_COVER_CHANGED
  ) {
    return (
      <HeroImage
        aspectRatio={isMobile ? 'square' : 'cinema'}
        resize={ImageResizeOptions.cover}
        loadingBehavior={ImageLoadingBehaviorOptions.blur}
        className={classes.activityImage}
        src={item.activity.data.src}
      />
    );
  }

  if (!content) {
    return null;
  }

  return (
    <RichContentViewer
      showSeeMore={enablePreview}
      content={content}
      usage="FeedItem"
      contentId={item.feedItemId}
      groupId={groupId}
    />
  );
};

function getFeedItemContent(item: IFeedItem) {
  let content: string;

  switch (item.activity?.activityType) {
    case FeedItemActivityType.ABOUT_GROUP_CHANGED:
    case FeedItemActivityType.DRAFT_JS:
      content = item.activity?.data.content;
      break;

    case FeedItemActivityType.GROUP_COVER_CHANGED:
    case FeedItemActivityType.USERS_JOINED:
      content = '';
      break;

    default:
      content = item.entity?.body?.content as string;
      break;
  }

  return content ? JSON.parse(content) : null;
}
