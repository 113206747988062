import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';

import { selectDialog } from 'store/application/selectors';
import { selectGroup } from 'store/groups/selectors';

import { Dialog } from 'common/components/Dialog/Dialog';
import { useController } from 'common/context/controller';

import { GroupMembershipButton } from '../GroupMembershipButton';

export function JoinGroupDialog() {
  const { t } = useTranslation();
  const { application$, group$ } = useController();

  const dialog = useSelector(selectDialog('joinGroup'));
  const group = useSelector(selectGroup(dialog.groupId as string));

  if (!group) {
    return null;
  }

  return (
    <Dialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      data-hook="join-group-dialog"
      title={t('groups-web.join.title')}
      caption={t('groups-web.join.text', {
        group: group.name,
        interpolation: { escapeValue: false },
      })}
      buttons={
        <GroupMembershipButton
          bw
          biOrigin="public_post_join_group_btn"
          groupId={dialog.groupId as string}
          onClick={handleSubmit}
        />
      }
    />
  );

  function handleSubmit() {
    group$.join({
      ...dialog.params,
      groupId: dialog.groupId as string,
    });
  }

  function handleClose() {
    application$.closeDialog('joinGroup');
  }
}

JoinGroupDialog.displayName = 'JoinGroupDialog';
