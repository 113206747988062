import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';

import { Modal, ModalProps } from '../Modal';
import { classes, st } from './ShareModal.st.css';
import { ShareComponent } from './ShareComponent';

interface ShareModalProps extends ModalProps {
  shareUrl: string;
  title: string;
  onShare?(): void;
}

export const ShareModal: React.FC<ShareModalProps> = (props) => {
  const { isOpen, onClose, title, shareUrl, onShare } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={st(classes.root)}>
        <Text
          typography={TextTypography.largeTitle}
          tagName="h2"
          className={classes.title}
        >
          {title}
        </Text>
        <ShareComponent onShare={onShare} shareUrl={shareUrl} />
      </div>
    </Modal>
  );
};

ShareModal.displayName = 'ShareModal';
