import React from 'react';
import { DialogProps } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Event as IEvent } from '@wix/ambassador-events-v1-event/types';

import { ModalV2 } from 'common/components/Modal';
import { Event } from 'common/components/Event/Event';

import { classes } from './RestrictedListModal.st.css';

interface Props extends DialogProps {
  events: IEvent[];
}

export const RestrictedListModal: React.FC<Props> = ({
  isOpen,
  onClose,
  events,
}) => {
  const { t } = useTranslation();

  return (
    <ModalV2 isOpen={isOpen} onClose={onClose} className={classes.root}>
      <ModalV2.Title>{t('groups-web.restriction.register')}</ModalV2.Title>
      <ModalV2.Content>
        {events.map((ev, i) => {
          return (
            <Event
              key={ev.id}
              event={ev}
              last={i === events.length - 1}
              groupId="groupId"
            />
          );
        })}
      </ModalV2.Content>
    </ModalV2>
  );
};
