import React from 'react';
import { Text, TextTypography } from 'wix-ui-tpa';

import { Block, BlockAlign, BlockFlow } from '../Block';
import { classes, st } from './Layout.st.css';
import { RESTRICTION } from './dataHooks';

export interface LayoutProps {
  title: string;
  subtitle: React.ReactNode;
  cta: React.ReactNode;
  mobile: boolean;
}

export const Layout: React.FC<LayoutProps> = ({ title, subtitle, cta }) => {
  return (
    <div className={st(classes.root, {})} data-hook={RESTRICTION}>
      <Block
        flow={BlockFlow.row}
        justify={BlockAlign.center}
        place={BlockAlign.center}
      >
        <Text typography={TextTypography.smallTitle} className="">
          {title}
        </Text>
        <Text typography={TextTypography.listText} className="" tagName="p">
          {subtitle}
        </Text>
        {cta}
      </Block>
    </div>
  );
};

Layout.displayName = 'Layout';
