import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { memberTabAdminActionDone } from '@wix/bi-logger-groups/v2';
import { ButtonPriority, Spinner } from 'wix-ui-tpa';

import { selectDialog } from 'store/application/selectors';
import { selectGroupStatus } from 'store/groups/selectors';

import { BIUserEntry } from 'common/bi-logger/types';
import { useController } from 'common/context/controller';
import {
  Dialog,
  DATA_HOOKS as DialogDataHooks,
} from 'common/components/Dialog';
import { Button } from 'common/components/Button';

import * as dataHooks from './dataHooks';

export function PrivateProfileDisclaimer() {
  const { t } = useTranslation();
  const bi = useBi();
  const { group$, application$ } = useController();

  const dialog = useSelector(selectDialog('disclaimer'));
  const status = useSelector(selectGroupStatus(dialog.groupId as string));

  const isLoading = status.membership.updating || status.requirements.loading;

  return (
    <Dialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      data-hook={dataHooks.PRIVATE_PROFILE_DISCLAIMER_DIALOG}
      title={t('groups-web.private-profile-disclaimer.title')}
      caption={t('groups-web.private-profile-disclaimer.subtitle')}
      buttons={
        <>
          <Button
            bw
            onClick={handleCancel}
            priority={ButtonPriority.secondary}
            data-hook={DialogDataHooks.cancelButton}
          >
            {t('groups-web.cancel')}
          </Button>
          <Button
            bw
            disabled={isLoading}
            onClick={handleSubmit}
            priority={ButtonPriority.primary}
            data-hook={DialogDataHooks.okButton}
          >
            {isLoading ? (
              <Spinner diameter={20} />
            ) : (
              t('groups-web.private-profile-disclaimer.ok')
            )}
          </Button>
        </>
      }
    />
  );

  function handleSubmit() {
    reportClick('confirm');
    application$.shareProfileConsent(dialog.groupId as string, true);
    group$.join({
      ...dialog.params,
      groupId: dialog.groupId as string,
    });
  }

  function handleCancel() {
    reportClick('cancel');
    application$.shareProfileConsent(dialog.groupId as string, false);
    application$.closeDialog('disclaimer');
  }

  function handleClose() {
    reportClick('close');
    application$.shareProfileConsent(dialog.groupId as string, false);
    application$.closeDialog('disclaimer');
  }

  function reportClick(action: string) {
    bi.report(
      memberTabAdminActionDone({
        action,
        origin: 'private_profile_in_groups',
        userEntry: BIUserEntry.SITE,
      }),
    );
  }
}

PrivateProfileDisclaimer.displayName = 'ProfilePrivacyDialog';
