import React from 'react';

export const LinkIcon = () => {
  return (
    <svg width="24px" height="25px" viewBox="0 0 24 25">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="03.-Groups-Page-/-03.1-Public-Group---Member-3dots-02"
          transform="translate(-785.000000, -425.000000)"
          fill="#222321"
        >
          <g
            id="Icons-/-Social-/-Copy-Link-Copy-Copy-Copy-Copy-Copy-2"
            transform="translate(785.000000, 424.140625)"
          >
            <g
              id="Icons-/-Social-/-Copy-Link-Copy-Copy-Copy-Copy-Copy"
              transform="translate(0.000000, 1.000000)"
            >
              <g>
                <g id="Mask">
                  <path
                    strokeWidth={0}
                    d="M7.59333827,14.8945341 L9.16048039,16.4627045 C9.27359432,16.5737619 9.42167074,16.6354604 9.58003025,16.6354604 C9.73736145,16.6354604 9.88543787,16.5737619 9.9985518,16.4627045 L16.4666121,9.99361592 C16.5776694,9.88153029 16.6393679,9.73345387 16.6393679,9.57612268 C16.6393679,9.41776317 16.5776694,9.26865844 16.4666121,9.15760113 L14.8984417,7.5894307 C14.6681006,7.3580613 14.292768,7.3580613 14.0613986,7.5894307 L7.59333827,14.0585193 C7.36299718,14.2888604 7.36299718,14.664193 7.59333827,14.8945341"
                    id="Fill-6"
                  />
                  <path
                    strokeWidth={0}
                    d="M22.0880661,1.91162546 C20.8551242,0.678683594 19.2149722,-8.8817842e-16 17.4719893,-8.8817842e-16 C15.7269498,-8.8817842e-16 14.0888544,0.678683594 12.8548843,1.91162546 L8.8084904,5.95904761 C8.69743308,6.07010493 8.63573458,6.21818135 8.63573458,6.37654086 C8.63573458,6.53490036 8.69743308,6.68297678 8.8084904,6.79506241 L10.7478802,8.73342388 L15.0883703,4.39293381 C16.3398217,3.14148239 18.3758725,3.14353901 19.6232106,4.39293381 C20.8736337,5.6423286 20.8736337,7.67735108 19.6232106,8.92777418 L15.2827206,13.2682643 L17.2046291,15.1901728 C17.3197997,15.3053433 17.4719893,15.3629286 17.6231507,15.3629286 C17.774312,15.3629286 17.9254733,15.3053433 18.0406439,15.1901728 L22.0880661,11.1437789 C23.3199796,9.91083709 23.9996915,8.27171338 23.9996915,6.5277022 C23.9996915,4.78369103 23.3199796,3.14456732 22.0880661,1.91162546"
                    id="Path"
                  />
                  <path
                    strokeWidth={0}
                    d="M13.3495006,15.2017927 L9.04397304,19.506292 C7.79354994,20.7587717 5.75852746,20.7567151 4.50913266,19.506292 C3.25870956,18.2558689 3.25870956,16.2218747 4.50913266,14.9714516 L8.81466025,10.665924 L6.87321385,8.72550591 C6.64801429,8.50133466 6.260342,8.50133466 6.03617075,8.72550591 L1.90956884,12.8521078 C-0.636522946,15.3981996 -0.636522946,19.5422828 1.90956884,22.0904312 C3.18364304,23.3634771 4.85567262,24 6.52873051,24 C8.2017884,24 9.87381798,23.3634771 11.1478922,22.0894029 L15.2734658,17.9628009 C15.3845231,17.8527719 15.4472499,17.7036672 15.4472499,17.5453077 C15.4472499,17.3869482 15.3855514,17.2378435 15.2744941,17.1267862 L13.3495006,15.2017927 Z"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
