import React from 'react';
import { Tooltip } from 'wix-ui-tpa';

export interface ReactedMembersTooltipProps {
  content: React.ReactNode;
}

export const ReactedMembersTooltip: React.FC<ReactedMembersTooltipProps> = ({
  children,
  content,
}) => {
  if (!content) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      maxWidth={250}
      showArrow
      appendTo="window"
      placement="top-start"
      content={content}
      hideDelay={150}
    >
      {children}
    </Tooltip>
  );
};
