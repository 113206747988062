import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Badge, BadgePriority } from 'wix-ui-tpa';

import { classes } from './AdminBadge.st.css';

export const AdminBadge: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Badge priority={BadgePriority.light}>
      {t('groups-web.roles.group_admin')}
    </Badge>
  );
};
