import React, { ChangeEvent, useMemo } from 'react';
import cls from 'classnames';

import { Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { BlackAndWhiteTextField } from 'common/components/BlackAndWhiteTextField';
import { skeleton } from 'common/components/Skeleton';

import { classes, st } from './GroupQuestion.st.css';

interface IGroupQuestionProps {
  index: number;
  disabled?: boolean;
  required?: boolean;
  question: string;
  value?: string;

  onChange?(value: string): void;
}

export function GroupQuestion(props: IGroupQuestionProps) {
  const { index, required, question, value, disabled } = props;

  const { t } = useTranslation();

  const error = useMemo(() => {
    if (required && !value?.trim()) {
      return t('groups-web.membership-questions.response.error');
    }

    if (value && value.length > 100) {
      return t('groups-web.membership-questions.response.error-max_length');
    }

    return undefined;
  }, [value, required]);

  return (
    <div className={st(classes.root, {})} data-hook="membership question root">
      <div className={classes.wrapper}>
        <Text className={classes.question}>
          {index}. {question}
          {required ? '*' : ''}
        </Text>
      </div>
      <BlackAndWhiteTextField
        newErrorMessage
        value={value}
        disabled={disabled}
        required={required}
        className={classes.input}
        error={!!error}
        errorMessage={error}
        onChange={handleChange}
        placeholder={t('groups-web.membership-questions.response.placeholder')}
      />
    </div>
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    props.onChange?.(event.target.value);
  }
}

GroupQuestion.displayName = 'GroupQuestion';

export function GroupQuestionSkeleton() {
  return (
    <div className={st(classes.root)}>
      <div className={classes.wrapper}>
        <Text className={cls(classes.question, skeleton)}>
          Lorem ipsum dolor sit amet.
        </Text>
      </div>
      <BlackAndWhiteTextField
        value="Lorem ipsum dolor sit amet"
        disabled
        className={cls(classes.input, skeleton)}
      />
    </div>
  );
}
