import React from 'react';
import { useSelector } from 'react-redux';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { ButtonPriority } from 'wix-ui-tpa';

import { selectFeedItems, selectFeedStatuses } from 'store/feed/selectors';
import { selectGroup } from 'store/groups/selectors';
import { selectAppData } from 'store/application/selectors';

import { useDidUpdate } from 'common/hooks';
import { UISref, useRouter } from 'common/router';
import { useController } from 'common/context/controller';
import { Button } from 'common/components/Button';
import { EmptyState } from 'common/components/EmptyState';

import {
  FeedItem,
  FeedItemSkeleton,
} from 'Group/Widget/DiscussionPage/GroupFeed/FeedItem';
import { ErrorState } from 'Group/Widget/ErrorState';

import settingsParams from 'FeedWidget/settingsParams';

import { classes } from './Feed.st.css';

export function Feed() {
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const router = useRouter();

  const items = useSelector(selectFeedItems);
  const { feed$ } = useController();
  const { groupId } = useSelector(selectAppData);
  const group = useSelector(selectGroup(groupId));
  const statuses = useSelector(selectFeedStatuses);

  const postsCount = settings.get(settingsParams.postsCount);

  useDidUpdate(() => {
    feed$.fetch(groupId, {
      limit: postsCount,
    });
  }, [groupId, group.membership, postsCount]);

  if (statuses.fetch.pending) {
    return (
      <>
        <FeedItemSkeleton />
        <FeedItemSkeleton />
        <FeedItemSkeleton />
      </>
    );
  }

  if (statuses.fetch.error) {
    return (
      <ErrorState
        onRetry={() =>
          feed$.fetch(groupId, {
            limit: postsCount,
          })
        }
      />
    );
  }

  if (!items.length) {
    return (
      <EmptyState
        title={t('groups-web.discussion.first.title')}
        content={t('groups-web.discussion.first.description')}
        button={{
          label: t('groups-web.discussion.first.button'),
          onClick: redirectToGroupPage,
        }}
        boxProps={{
          withSideBorders: true,
        }}
      />
    );
  }

  return (
    <>
      {items.map((item) => (
        <FeedItem
          enablePreview
          key={item.feedItemId}
          item={{
            ...item,
            permissions: {
              ...item.permissions,
              canUpdate: false,
              canDelete: false,
            },
          }}
        />
      ))}
      <UISref state="group" params={{ slug: group.slug }}>
        <Button
          as="a"
          fullWidth={isMobile}
          priority={ButtonPriority.primary}
          className={classes.showMore}
        >
          {t('groups-web.feed.show-more.label')}
        </Button>
      </UISref>
    </>
  );

  function redirectToGroupPage() {
    router.go('group', { slug: group.slug });
  }
}
