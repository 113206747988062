import React, { useMemo } from 'react';
import { Button as TPAButton, ButtonProps as TPAButtonProps } from 'wix-ui-tpa';

import { ButtonType } from 'settings/consts';

import { useTheme } from '../../context/theme';

import { classes, st, vars } from './Button.st.css';

export interface ButtonProps extends TPAButtonProps {
  bw?: boolean;
}

/**
 * Note:
 * Be careful with using, useStyles, and useSettings here,
 * because group settings and group list settings
 * can have different values by default for the same keys.
 *
 * F.e.
 * buttonType
 * for group list = rectangle,
 * for group = rounded
 *
 * cornerRadius:
 * groups list = 100px,
 * group = 0px
 */
export const Button: React.FC<ButtonProps> = (props) => {
  const { style, bw = false, className, ...restProps } = props;
  const { buttonBorderRadius, buttonType } = useTheme();

  const buttonStyle = useMemo(() => {
    if (buttonType === ButtonType.rounded && buttonBorderRadius !== undefined) {
      return {
        [vars.radius]: `${buttonBorderRadius!}px`,
      };
    }
    return null;
  }, [buttonBorderRadius, buttonType]);

  const mergedStyle = useMemo(
    () => ({
      ...buttonStyle,
      ...style,
    }),
    [style, buttonStyle],
  );

  return (
    <TPAButton
      {...restProps}
      className={st(
        classes.root,
        {
          bw,
          buttonType: buttonType!,
        },
        className,
      )}
      style={mergedStyle}
    />
  );
};

Button.displayName = 'Button';
