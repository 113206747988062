import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { selectAppData } from 'store/application/selectors';
import { selectGroupFetchStatus } from 'store/groups/selectors';

import { Spinner } from 'common/components/Spinner';
import { EmptyState } from 'common/components/EmptyState';

import { GroupMembershipDialogs } from 'Group/Widget/GroupMembership';

import settingsParams from '../settingsParams';

import { Feed } from './Feed';
import { GroupDetails } from './GroupDetails';
import { FEED_WIDGET_WRAPPER } from './dataHooks';

import { st, classes } from './Widget.st.css';

export const Widget = () => {
  const { t } = useTranslation();
  const settings = useSettings();

  const { groupId } = useSelector(selectAppData);
  const { loading } = useSelector(
    selectGroupFetchStatus(settings.get(settingsParams.groupId)),
  );

  if (loading) {
    return <EmptyState content={<Spinner />} />;
  }

  if (!groupId) {
    return (
      <EmptyState
        title={t('groups-web.feed-widget.empty.title')}
        content={t('groups-web.feed-widget.empty.description')}
      />
    );
  }

  return (
    <div className={st(classes.root, {})} data-hook={FEED_WIDGET_WRAPPER}>
      <GroupMembershipDialogs />
      <GroupDetails />
      <Feed />
    </div>
  );
};
