import React from 'react';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import {
  Avatar,
  AvatarSize,
  ButtonPriority,
  Text,
  TextTypography,
  Spinner,
} from 'wix-ui-tpa';

import { Role } from '@wix/ambassador-social-groups-v2-group-role/types';

import type { IGroupMember } from 'api/members/types';
import { selectChangeRoleStatus } from 'store/members/selectors';

import { useController } from 'common/context/controller';

import { useDidUpdate } from 'common/hooks';
import { Dialog } from 'common/components/Dialog/Dialog';
import { Button } from 'common/components/Button';

import { st, classes } from './ChangeMemberRoleDialog.st.css';
import { useSelector } from 'react-redux';
import { memberTabAdminActionDone } from '@wix/bi-logger-groups/v2';
import { BIUserEntry } from 'common/bi-logger/types';

export interface IChangeMemberRoleDialogProps {
  isOpen: boolean;
  groupId: string;
  member: IGroupMember;

  onClose(): void;
}

export function ChangeMemberRoleDialog(props: IChangeMemberRoleDialogProps) {
  const { member, isOpen, onClose } = props;
  const isAdmin = member.role === Role.ADMIN;
  const { t } = useTranslation();
  const bi = useBi();
  const { members$ } = useController();

  const status = useSelector(
    selectChangeRoleStatus(member.siteMemberId as string),
  );

  useDidUpdate(() => {
    if (!status.loading && !status.error) {
      onClose();
    }
  }, [status.loading, status.error]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      buttons={
        <>
          <Button bw onClick={onClose} priority={ButtonPriority.secondary}>
            {t('groups-web.cancel')}
          </Button>
          <Button
            bw
            onClick={handleSubmit}
            disabled={status.loading}
            priority={ButtonPriority.primary}
          >
            {status.loading ? (
              <Spinner diameter={20} />
            ) : isAdmin ? (
              t('groups-web.group.actions.remove-admin-role.modal.confirm')
            ) : (
              t('groups-web.group.actions.add-admin-role.modal.confirm')
            )}
          </Button>
        </>
      }
    >
      <div className={st(classes.root)}>
        <div className={classes.header}>
          <Avatar
            size={AvatarSize.large}
            src={member.imageUrl}
            name={member.name}
          />
          <Text
            typography={TextTypography.runningText}
            className={classes.memberName}
          >
            {member.name}
          </Text>
          <Text
            typography={TextTypography.largeTitle}
            tagName="h1"
            className={classes.title}
          >
            {isAdmin
              ? t('groups-web.group.actions.remove-admin-role.modal.title')
              : t('groups-web.group.actions.add-admin-role.modal.title')}
          </Text>
        </div>
        <div>
          <Text
            typography={TextTypography.runningText}
            className={classes.description}
            tagName="div"
          >
            {isAdmin
              ? t(
                  'groups-web.group.actions.remove-admin-role.modal.description',
                  { memberFullName: member.name },
                )
              : t('groups-web.group.actions.add-admin-role.modal.description')}
          </Text>

          <ul className={classes.list}>
            <Text
              tagName="li"
              typography={TextTypography.runningText}
              className={classes.adminPermissionOption}
            >
              {t('groups-web.group.actions.admin-role-description.posts')}
            </Text>
            <Text
              tagName="li"
              typography={TextTypography.runningText}
              className={classes.adminPermissionOption}
            >
              {t('groups-web.group.actions.admin-role-description.comments')}
            </Text>
            <Text
              tagName="li"
              typography={TextTypography.runningText}
              className={classes.adminPermissionOption}
            >
              {t('groups-web.group.actions.admin-role-description.members')}
            </Text>
          </ul>
        </div>
      </div>
    </Dialog>
  );

  function handleSubmit() {
    members$.changeRole(
      props.groupId,
      member.siteMemberId as string,
      isAdmin ? Role.MEMBER : Role.ADMIN,
    );

    bi.report(
      memberTabAdminActionDone({
        action: isAdmin ? 'remove_admin' : 'set_admin',
        group_id: props.groupId,
        origin: 'dialog_screen_btn_clk',
        userEntry: BIUserEntry.SITE,
        site_member_id: member.siteMemberId as string,
      }),
    );
  }
}

ChangeMemberRoleDialog.displayName = 'ChangeMemberRoleDialog';
