import React from 'react';

export const AddEmojiIcon: React.FC<React.SVGAttributes<SVGElement>> = (
  props,
) => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" {...props}>
    <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
      <path
        d="M9,1 C13.4109091,1 17,4.58909091 17,9 C17,13.4109091 13.4109091,17 9,17 C4.58909091,17 1,13.4109091 1,9 C1,4.58909091 4.58909091,1 9,1 Z M9,1.72727273 C4.98981818,1.72727273 1.72727273,4.98981818 1.72727273,9 C1.72727273,13.0101818 4.98981818,16.2727273 9,16.2727273 C13.0101818,16.2727273 16.2727273,13.0101818 16.2727273,9 C16.2727273,4.98981818 13.0101818,1.72727273 9,1.72727273 Z M5.91730909,10.2222545 C6.09403636,10.1277091 6.31367273,10.1909818 6.4104,10.3684364 C6.92603636,11.3204364 7.91876364,11.9109818 9.00094545,11.9109818 C10.0816727,11.9109818 11.0736727,11.3204364 11.5893091,10.3691636 C11.6860364,10.1917091 11.9085818,10.1277091 12.0824,10.2229818 C12.2591273,10.3189818 12.3245818,10.5400727 12.2285818,10.7160727 C11.5856727,11.9022545 10.3485818,12.6382545 9.00094545,12.6382545 C7.65112727,12.6382545 6.41403636,11.9015273 5.77112727,10.7153455 C5.67512727,10.5386182 5.74058182,10.3182545 5.91730909,10.2222545 Z M6.09090909,6.81818182 C6.49309091,6.81818182 6.81818182,7.14327273 6.81818182,7.54545455 C6.81818182,7.94690909 6.49309091,8.27272727 6.09090909,8.27272727 C5.68945455,8.27272727 5.36363636,7.94690909 5.36363636,7.54545455 C5.36363636,7.14327273 5.68945455,6.81818182 6.09090909,6.81818182 Z M11.3949091,7.03127273 C11.6792727,6.74690909 12.1396364,6.74690909 12.424,7.03127273 C12.7076364,7.31563636 12.7076364,7.77527273 12.424,8.05963636 C12.1396364,8.344 11.6792727,8.344 11.3949091,8.05963636 C11.1105455,7.77527273 11.1105455,7.31563636 11.3949091,7.03127273 Z"
        strokeWidth="0.4"
      ></path>
    </g>
  </svg>
);
