import React from 'react';
import { DialogProps } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import type { IGroupMember } from 'api/members/types';

import { ModalV2 } from 'common/components/Modal/ModalV2';
import { UserCard } from 'common/components/Cards/UserCard';

import settingsParams from 'Group/settingsParams';
import { FollowButton } from 'Group/Widget/MembersPage/GroupMembers/Member';

import { classes, st } from './UsersJoinedModal.st.css';

interface UserJoinedModalProps extends DialogProps {
  members: IGroupMember[];
}

export const UserJoinedModal: React.FC<UserJoinedModalProps> = ({
  onClose,
  isOpen,
  members,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();

  const showBadges = settings.get(settingsParams.showMembersBadges);

  return (
    <ModalV2 onClose={onClose} isOpen={isOpen}>
      <ModalV2.Title>{t('groups-web.users-joined-popup.title')}</ModalV2.Title>
      <ModalV2.Content className={st(classes.contentWrapper)}>
        <ul className={classes.profiles}>
          {members.map((member) => (
            <li key={member.siteMemberId} className={classes.profile}>
              <UserCard
                profile={member}
                showBadges={showBadges}
                action={
                  <FollowButton
                    bw
                    isPrivate={member.isPrivate}
                    memberId={member.siteMemberId!}
                  />
                }
              />
            </li>
          ))}
        </ul>
      </ModalV2.Content>
    </ModalV2>
  );
};
