import React from 'react';
import cls from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Popover, PopoverTriggerAction, Text } from 'wix-ui-tpa';

import { useTheme } from 'common/context/theme';

import { classes } from './PrivateProfileHint.st.css';

interface IPrivateProfileHintProps {
  disabled?: boolean;
  className?: string;
  children: React.ReactElement;
}

export function PrivateProfileHint(props: IPrivateProfileHintProps) {
  const { isSSR } = useEnvironment();
  const [isOpen, setIsOpen] = React.useState(false);

  const { t } = useTranslation();
  const { host } = useTheme();

  if (props.disabled) {
    return props.children;
  }

  const content = React.cloneElement(props.children, {
    href: undefined,
    onClick: handleOpen,
  });

  if (isSSR) {
    return <>{content}</>;
  }

  const root = document.getElementById(host.id)?.firstChild as HTMLElement;

  return (
    <Popover
      shown={isOpen}
      placement="top"
      zIndex={100001}
      appendTo={root || 'parent'}
      onClickOutside={handleClose}
      disableClickOutsideWhenClosed
      wiredToSiteColors={false}
      triggerAction={PopoverTriggerAction.click}
      className={cls(classes.root, props.className)}
    >
      <Popover.Element>{content}</Popover.Element>
      <Popover.Content>
        <Text className={classes.text}>
          {t('groups-web.private-profile-hint.description')}
        </Text>
      </Popover.Content>
    </Popover>
  );

  function handleClose() {
    setIsOpen(false);
  }

  function handleOpen(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    setIsOpen((currentShownValue) => {
      return !currentShownValue;
    });
  }
}
