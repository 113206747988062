import React from 'react';

export const PinIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props,
) => (
  <svg viewBox="0 0 18 17" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="currentColor"
      d="M5.34 12.338L2.152 15.59l-.73-.73 3.188-3.252L1 7.997l.595-.66a5.799 5.799 0 0 1 4.308-1.918H7.71l1.806-1.806V2.83c0-.821.326-1.609.907-2.19L11.065 0 17 5.935l-.641.642c-.581.58-1.369.907-2.19.907h-.782L11.581 9.29v1.807a5.794 5.794 0 0 1-1.919 4.307L9 16l-3.66-3.662z"
    />
  </svg>
);
