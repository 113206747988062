import React from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa';
import { useSelector } from 'react-redux';

import { IFeedItem } from 'store/feed/types';
import { selectIsJoinedGroupMember } from 'store/groups/selectors';

import { useController } from 'common/context/controller';
import { Box } from 'common/components/Box/Box';

import { PinIcon } from 'Group/Widget/icons/PinIcon';

import { AuthorInfo } from './AuthorInfo';
import { FeedItemContent } from './FeedItemContent';
import { FeedItemActions } from './FeedItemActions';
import { Comments } from './Comments';
import { Topics } from './Topics';
import { ReactionsAndCommentsCounterRow } from './ReactionsAndCommentsCounterRow';
import { SuggestedGroupLabel } from './SuggestedGroupLabel';
import { SocialBar } from './SocialBar';

import { FEED_ITEM } from './dataHooks';

import { classes, st } from './FeedItem.st.css';

interface FeedItemProps {
  item: IFeedItem;
  enablePreview?: boolean;
  showGroupName?: boolean;
  showSuggestedGroupLabel?: boolean;
  className?: string;
}

export const FeedItem: React.FC<FeedItemProps> = ({
  item,
  enablePreview,
  showGroupName,
  showSuggestedGroupLabel,
  className,
}) => {
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const { comments$ } = useController();
  const { t } = useTranslation();

  const groupId = item.applicationContext.groupId as string;
  const isJoined = useSelector(
    selectIsJoinedGroupMember(item.applicationContext.group),
  );

  const [showComments, setShowComments] = React.useState<boolean>(!isMobile);

  const useNewReactions = experiments.enabled('specs.groups.NewReactions');

  return (
    <Box
      data-hook={FEED_ITEM}
      withSideBorders={!isMobile}
      className={st(
        classes.root,
        {
          mobile: isMobile,
          withReactions: item.reactions.total > 0,
        },
        className,
      )}
    >
      {item.pin ? (
        <div className={classes.pinnedHeader}>
          <PinIcon className={classes.pinnedIcon} width={18} height={17} />
          <Text className={classes.pinnedHeaderText}>
            {t('groups-web.discussion.feed.pined-post')}
          </Text>
        </div>
      ) : null}

      {showSuggestedGroupLabel && !isJoined ? (
        <SuggestedGroupLabel
          groupId={groupId}
          className={classes.suggestedLabel}
        />
      ) : null}

      <div className={classes.meta}>
        <AuthorInfo item={item} showGroupName={showGroupName} />
        <FeedItemActions item={item} />
      </div>
      <FeedItemContent enablePreview={enablePreview} item={item} />

      <Topics item={item} />

      <div className={classes.social}>
        {useNewReactions ? (
          <SocialBar
            item={item}
            onCommentsToggle={toggleComments}
            onCommentClick={handleCommentClick}
          />
        ) : (
          <ReactionsAndCommentsCounterRow
            item={item}
            onCommentsCounterClick={toggleComments}
          />
        )}

        <Comments item={item} isJoined={isJoined} collapsed={!showComments} />
      </div>
    </Box>
  );

  function handleCommentClick() {
    setShowComments(true);
    comments$.openCommentBox(item.feedItemId as string, {
      shouldFocus: true,
      shouldScroll: true,
    });
  }

  function toggleComments() {
    setShowComments(!showComments);
  }
};
