import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { ButtonPriority, Spinner } from 'wix-ui-tpa';

import { selectDialog } from 'store/application/selectors';
import {
  selectGroup,
  selectGroupQuestions,
  selectGroupStatus,
} from 'store/groups/selectors';

import { ModalV2 } from 'common/components/Modal';
import { useController } from 'common/context/controller';
import { Button } from 'common/components/Button';

import { GroupMembershipButton } from '../../GroupMembershipButton';

import { GroupQuestion } from './GroupQuestion';
import { classes } from './GroupQuestionsDialog.st.css';

export function GroupQuestionsDialog() {
  const { t } = useTranslation();
  const { application$, group$ } = useController();

  const dialog = useSelector(selectDialog('groupQuestions'));
  const group = useSelector(selectGroup(dialog.groupId as string));
  const status = useSelector(selectGroupStatus(dialog.groupId as string));
  const questions = useSelector(selectGroupQuestions(dialog.groupId as string));

  const [answers, setAnswers] = useState<{
    [questionId: string]: string;
  }>({});

  const isValid = useMemo(() => {
    return questions.every(
      (question) => !question.required || !!answers[question.id as string],
    );
  }, [questions, answers]);

  useEffect(() => {
    if (dialog.groupId) {
      group$.fetchQuestions(dialog.groupId);
    }
  }, [dialog.groupId]);

  useEffect(() => {
    setAnswers(
      questions.reduce((acc, question) => {
        acc[question.id as string] = '';
        return acc;
      }, {} as { [questionId: string]: string }),
    );
  }, [questions.map((question) => question.id).join(',')]);

  return (
    <ModalV2
      isOpen={dialog.isOpen}
      onClose={handleClose}
      data-hook="membership questions modal"
    >
      <ModalV2.Title>
        {t('groups-web.membership-questions.title')}
      </ModalV2.Title>
      <ModalV2.Subtitle>
        {t('groups-web.membership-questions.subtitle', {
          groupName: group.name,
        })}
      </ModalV2.Subtitle>
      <ModalV2.Content>
        <SwitchTransition>
          {(() => {
            if (status.questions.loading) {
              return (
                <CSSTransition
                  timeout={300}
                  classNames={classes as CSSTransitionClassNames}
                  key="loader"
                >
                  <Spinner className={classes.spinner} />
                </CSSTransition>
              );
            }

            return (
              <CSSTransition
                timeout={300}
                classNames={classes as CSSTransitionClassNames}
                key="viewer"
              >
                <ul>
                  {questions.map((question, index) => (
                    <li key={question.id} className={classes.question}>
                      <GroupQuestion
                        index={index + 1}
                        disabled={status.membership.updating}
                        required={question.required}
                        question={question.text as string}
                        value={answers[question.id as string]}
                        onChange={handleAnswerChange(question.id as string)}
                      />
                    </li>
                  ))}
                </ul>
              </CSSTransition>
            );
          })()}
        </SwitchTransition>
      </ModalV2.Content>
      <ModalV2.Footer
        okButton={
          <GroupMembershipButton
            bw
            disabled={!isValid || status.questions.loading}
            groupId={dialog.groupId as string}
            onClick={handleSubmit}
            data-hook="membership questions submit button"
          >
            {t('groups-web.submit')}
          </GroupMembershipButton>
        }
        cancelButton={
          <Button
            bw
            upgrade
            priority={ButtonPriority.secondary}
            onClick={handleClose}
          >
            {t('groups-web.cancel')}
          </Button>
        }
      />
    </ModalV2>
  );

  function handleAnswerChange(questionId: string) {
    return (value: string) => {
      setAnswers((answers) => ({
        ...answers,
        [questionId]: value,
      }));
    };
  }

  function handleClose() {
    application$.closeDialog('groupQuestions');
  }

  function handleSubmit() {
    const membershipQuestionAnswers = Object.keys(answers).map(
      (questionId) => ({
        id: questionId,
        text: answers[questionId],
      }),
    );

    group$.join({
      ...dialog.params,
      groupId: dialog.groupId as string,
      membershipQuestionAnswers,
    });
  }
}
