import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Text, TextButton } from 'wix-ui-tpa';

import { IUsersJoinedActivity } from 'api/feed/types';
import { selectGroupName, selectGroupSlug } from 'store/groups/selectors';
import { IGroup } from 'store/groups/types';

import { UserJoinedModal } from '../UsersJoinedModal';
import { ClickableGroupName } from '../ClickableGroupName';

import { classes, st } from '../ActivityStatus.st.css';

interface UsersJoinedProps extends IUsersJoinedActivity {
  group?: IGroup;
}

export const UsersJoined: React.FC<UsersJoinedProps> = ({
  authorUserId,
  userIds = [],
  users = {},
  group,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const groupName = useSelector(selectGroupName(group));
  const slug = useSelector(selectGroupSlug(group));

  const groupNameAsLink = (
    <ClickableGroupName slug={slug} groupId={group?.id!} />
  );

  const notClickablePart = (
    <Text className={st(classes.root, { mobile: isMobile })} />
  );

  const [isUserJoinedModalOpened, setIsUserJoinedModalOpened] =
    React.useState<boolean>(false);

  const otherUsers = React.useMemo(() => {
    return userIds.filter((userId) => userId !== authorUserId);
  }, [userIds]);

  if (userIds.length === 1) {
    return renderUserJoined();
  }

  if (userIds.length === 2) {
    return render2UsersJoined();
  }

  return renderUsersJoined();

  function renderUserJoined() {
    return (
      <Trans
        i18nKey={
          groupName
            ? 'groups-web.activity-post.user.joined.with-group-name'
            : 'groups-web.activity-post.user.joined'
        }
        values={{ groupName }}
        components={[notClickablePart, groupNameAsLink]}
      />
    );
  }

  function render2UsersJoined() {
    const anotherUser = users[otherUsers[0]];
    return (
      <Trans
        i18nKey={
          groupName
            ? 'groups-web.activity-post.2users.joined.with-group-name'
            : 'groups-web.activity-post.2users.joined'
        }
        values={{
          userName:
            users[anotherUser?.siteMemberId!]?.name ||
            t('groups-web.member.anonymous'),
          groupName,
        }}
        components={[
          notClickablePart,
          <TextButton
            className={st(classes.link, { mobile: isMobile })}
            key="1"
            onClick={() => {
              // TODO: check if profile is private
              // userActions.openUserProfile(anotherUser.siteMemberId!)
            }}
          >
            user name
          </TextButton>,
          groupNameAsLink,
        ]}
      />
    );
  }

  function renderUsersJoined() {
    return (
      <>
        <Trans
          i18nKey={
            groupName
              ? 'groups-web.activity-post.users.joined.with-group-name'
              : 'groups-web.activity-post.users.joined'
          }
          values={{
            N: userIds.length - 1,
            groupName,
          }}
          components={[
            notClickablePart,
            <TextButton
              className={st(classes.link, { mobile: isMobile })}
              key="1"
              onClick={openUserJoinedModal}
            >
              N others
            </TextButton>,
            groupNameAsLink,
          ]}
        />
        <UserJoinedModal
          members={Object.values(users)}
          isOpen={isUserJoinedModalOpened}
          onClose={closeUserJoinedModal}
        />
      </>
    );
  }

  function openUserJoinedModal() {
    setIsUserJoinedModalOpened(true);
  }

  function closeUserJoinedModal() {
    setIsUserJoinedModalOpened(false);
  }
};
