import React from 'react';
import { useSelector } from 'react-redux';
import cls from 'classnames';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { ButtonPriority, Spinner } from 'wix-ui-tpa';
import { groupsRequestJoinAGroup } from '@wix/bi-logger-groups/v2';

import {
  selectGroupPrivacyStatus,
  selectGroupStatus,
  selectIsJoinedGroupMember,
  selectIsPendingGroupMember,
} from 'store/groups/selectors';

import { useCurrentStateAndParams } from 'common/router';
import { Button, ButtonProps } from 'common/components/Button';
import { useController } from 'common/context/controller';
import { BIUserEntry } from 'common/bi-logger/types';

import { selectButtonLabel } from './selectors';

import { MEMBERSHIP_BUTTON } from './dataHooks';

import { classes } from './GroupMembershipButton.st.css';

export interface MembershipButtonProps extends ButtonProps {
  groupId: string;
  biOrigin?: string;
  children?: string;

  onClick?(): void;
}

export function GroupMembershipButton(props: MembershipButtonProps) {
  const { groupId, className, biOrigin, onClick, ...rest } = props;
  const { t } = useTranslation();
  const bi = useBi();
  const { params } = useCurrentStateAndParams();

  const { application$, group$ } = useController();
  const label = useSelector(selectButtonLabel(groupId));
  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));
  const isPending = useSelector(selectIsPendingGroupMember(groupId));
  const status = useSelector(selectGroupStatus(groupId));
  const privacyStatus = useSelector(selectGroupPrivacyStatus(groupId));

  const isLoading = status.membership.updating || status.requirements.loading;

  if (isJoined && !props.children) {
    return null;
  }

  return (
    <div className={cls(classes.root, className)}>
      <Button
        upgrade
        fullWidth
        data-hook={MEMBERSHIP_BUTTON}
        onClick={handleClick}
        priority={isPending ? ButtonPriority.secondary : ButtonPriority.primary}
        {...rest}
        disabled={isLoading || rest.disabled}
      >
        {isLoading ? <Spinner diameter={20} /> : t(props.children || label)}
      </Button>
    </div>
  );

  function handleClick() {
    const hasCustomHandler = !!onClick;

    if (hasCustomHandler) {
      onClick();
      return;
    }

    if (isPending) {
      application$.showDialog({
        groupId,
        dialog: 'cancelJoinRequest',
      });

      return;
    }

    group$.join({
      groupId,
      autoInviteId: params.autoInviteId,
    });

    bi.report(
      groupsRequestJoinAGroup({
        group_id: groupId,
        origin: biOrigin,
        userEntry: BIUserEntry.SITE,
        type: (privacyStatus || '').toLowerCase(),
      }),
    );
  }
}

GroupMembershipButton.displayName = 'GroupMembershipButton';
