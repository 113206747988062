import React from 'react';
import { useSelector } from 'react-redux';

import { Trans, useTranslation } from '@wix/yoshi-flow-editor';

import {
  AvatarSize,
  ButtonPriority,
  Text,
  TextPriority,
  TextTypography,
  Spinner,
} from 'wix-ui-tpa';

import { selectCurrentUser } from 'store/application/selectors';
import { selectGroupName } from 'store/groups/selectors';

import { UserCard } from 'common/components/Cards/UserCard';
import { Button } from 'common/components/Button/Button';

import { TopicSelect } from '../TopicSelect';

import { classes } from '../PostEditorModal.st.css';

import { IPostEditorProps } from './types';
import dataHooks from 'Group/Widget/DiscussionPage/GroupFeed/PostEditorModal/dataHooks';

export function PostEditorDesktop(props: IPostEditorProps) {
  const { feedItem, children, topics, group, showGroupName, cancelButtonText } =
    props;

  const { t } = useTranslation();

  const user = useSelector(selectCurrentUser);
  const groupName = useSelector(selectGroupName(group)) || '...';

  return (
    <>
      <div className={classes.profileInfo}>
        <UserCard
          bw
          showBadges={false}
          profile={user}
          avatarSize={AvatarSize.large}
        >
          {showGroupName ? (
            <div>
              <Trans
                i18nKey="groups-web.discussion.new-post.create-post-in-group"
                values={{
                  groupName,
                }}
                components={[
                  <Text
                    typography={TextTypography.runningText}
                    priority={TextPriority.secondary}
                  >
                    text with opacity
                  </Text>,
                  <Text typography={TextTypography.runningText}>
                    group name
                  </Text>,
                ]}
              />
            </div>
          ) : null}
        </UserCard>
      </div>
      <div className={classes.wrapper}>{children}</div>
      <div className={classes.actionButtons}>
        <TopicSelect
          isEditable
          groupId={group.id as string}
          selected={topics}
          feedItemId={feedItem?.feedItemId}
          onChange={props.onTopicsChange}
        />
        <div>
          <Button
            className={classes.cancelButton}
            onClick={props.onCancel}
            data-hook={dataHooks.cancelButton}
          >
            {cancelButtonText || t('groups-web.discussion.new-post.cancel')}
          </Button>
          <Button
            bw
            onClick={props.onSubmit}
            priority={ButtonPriority.primary}
            disabled={props.disabled || props.isLoading}
            data-hook={dataHooks.confirmButton}
          >
            {props.isLoading ? (
              <Spinner diameter={20} />
            ) : (
              t('groups-web.discussion.new-post.publish')
            )}
          </Button>
        </div>
      </div>
    </>
  );
}
