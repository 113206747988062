import React from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';

import {
  WixCommentsApiProvider,
  CommentsProps,
} from '@wix/comments-ooi-client';

import { SocialGroupStore } from 'store/SocialGroupsStore';

import { ControllerProvider } from 'common/context/controller';
import { ThemeProvider } from 'common/context/theme';
import { Router } from 'common/router';
import { Toasts } from 'common/components/Toasts';

import { IControllerProps } from 'controllers/types';

import { Widget } from './Widget';

function FeedWidget(props: WidgetProps<IControllerProps & CommentsProps>) {
  return (
    <SocialGroupStore state={props.store}>
      <ThemeProvider host={props.host}>
        <ControllerProvider {...props}>
          <WixCommentsApiProvider {...props}>
            <Toasts />
            <Router>
              <Widget />
            </Router>
          </WixCommentsApiProvider>
        </ControllerProvider>
      </ThemeProvider>
    </SocialGroupStore>
  );
}

export default FeedWidget;
