import React from 'react';
import { Popover } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { Modal, ModalSkin } from 'common/components/Modal/Modal';

import { AddReactionButton } from './AddReactionButton';
import { EmojiPicker } from './EmojiPicker';

import { st, classes } from './ReactionsPicker.st.css';

interface ReactionsPickerProps {
  onSelect(reactionCode: string): void;
  className?: string;
}

export const ReactionsPicker: React.FC<ReactionsPickerProps> = ({
  className,
  onSelect,
}) => {
  const [isOpened, setIsIOpened] = React.useState(false);
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  return (
    <div
      tabIndex={1}
      aria-label={t('groups-web.a11y.reactions.more')}
      className={st(classes.root, { mobile: isMobile }, className)}
    >
      {isMobile ? (
        <>
          <AddReactionButton onClick={openPicker} />
          <Modal
            isOpen={isOpened}
            onClose={closePicker}
            skin={ModalSkin.REACTIONS}
          >
            <EmojiPicker onSelect={handleEmojiPickerOnselect} />
          </Modal>
        </>
      ) : (
        <Popover
          appendTo="viewport"
          placement="top-start"
          shown={isOpened}
          disableClickOutsideWhenClosed={true}
          onClickOutside={closePicker}
          className={classes.reactionsPickerPopover}
        >
          <Popover.Element>
            <AddReactionButton onClick={openPicker} />
          </Popover.Element>
          <Popover.Content>
            <EmojiPicker onSelect={handleEmojiPickerOnselect} />
          </Popover.Content>
        </Popover>
      )}
    </div>
  );

  function handleEmojiPickerOnselect(reactionCode: string) {
    onSelect(reactionCode);
    closePicker();
  }

  function closePicker() {
    setIsIOpened(false);
  }

  function openPicker() {
    setIsIOpened(true);
  }
};
