import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';
import {
  Trans,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';

import { Event as IEvent } from '@wix/ambassador-events-v1-event/types';

import { Layout, LayoutProps } from 'common/components/Restriction/Layout';
import { Button } from 'common/components/Button';
import { useHref } from 'common/router';

import { selectGroupName } from 'store/groups/selectors';
import { selectDialog } from 'store/application/selectors';

import { classes } from './Layout/Layout.st.css';

interface Props {
  past: IEvent[];
  upcoming: IEvent[];
  onViewEvents(events: IEvent[]): void;
}

export const Restricted: React.FC<Props> = ({
  past,
  upcoming,
  onViewEvents,
}) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { experiments } = useExperiments();

  const dialog = useSelector(selectDialog('eventsRestriction'));
  const groupName = useSelector(selectGroupName(dialog.groupId as string));

  const groupsUrl = useHref('groups', {}, { absolute: true });

  let p: LayoutProps;

  if (upcoming.length) {
    p = {
      cta: (
        <Button
          priority={ButtonPriority.primary}
          onClick={() => onViewEvents(upcoming)}
          className={classes.cta}
        >
          {t('groups-web.restriction.view-event_icu', {
            count: upcoming.length,
          })}
        </Button>
      ),
      mobile: isMobile,
      subtitle: (
        <Trans
          i18nKey="groups-web.restriction.register.event"
          values={{ groupName }}
          components={{ bold: <b /> }}
        />
      ),
      title: t('groups-web.restriction.register'),
    };
  } else {
    p = {
      title: t('groups-web.restriction.invalid.title'),
      subtitle: t('groups-web.restriction.invalid.subtitle_icu', {
        count: past.length,
      }),
      mobile: isMobile,
      cta: (
        <Button
          as="a"
          href={groupsUrl}
          priority={ButtonPriority.primary}
          className={classes.cta}
        >
          {t('groups-web.restriction.invalid.action')}
        </Button>
      ),
    };
  }
  return <Layout {...p!} />;
};
