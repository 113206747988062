import React from 'react';
import { IconToggleLabelPlacement } from 'wix-ui-tpa';

import { AddEmojiIcon } from 'Group/Widget/icons/AddEmojiIcon';

import { Reaction } from '../Reaction';

import { classes } from './ReactionsPicker.st.css';

interface AddReactionButtonProps {
  onClick(): void;
}

export const AddReactionButton: React.FC<AddReactionButtonProps> = ({
  onClick,
}) => {
  return (
    <Reaction
      cta
      label="+"
      icon={<AddEmojiIcon className={classes.icon} />}
      checked={false}
      labelPlacement={IconToggleLabelPlacement.START}
      onClick={onClick}
      reactions={[]}
    />
  );
};
