import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { EmptyState } from 'common/components/EmptyState';

export function CommentsError() {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('groups-web.discussion.error-state.title')}
      content={t(
        'groups-weGroupFeed/FeedItemList/virtual/Footer.tsx:b.discussion.error.feed-item.message',
      )}
    />
  );
}

CommentsError.displayName = 'CommentsError';
