import React from 'react';

export const Twitter: React.FC = () => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20">
      <defs>
        <path
          strokeWidth={0}
          id="twitter"
          d="M24 2.337a9.64 9.64 0 0 1-2.816.785 5.016 5.016 0 0 0 2.16-2.757c-.965.58-2.02.99-3.12 1.212A4.887 4.887 0 0 0 14.817.35c-1.88.752-3.117 2.591-3.121 4.64.002.382.045.763.128 1.135A13.91 13.91 0 0 1 1.68.91a4.941 4.941 0 0 0-.672 2.509 4.73 4.73 0 0 0 1.808 4.154A14.969 14.969 0 0 1 .208 6.95v.063c.17 2.445 1.956 4.46 4.336 4.893a3.53 3.53 0 0 1-1.104.175c-.28 0-.559-.029-.832-.09.66 2.04 2.523 3.43 4.64 3.466A9.697 9.697 0 0 1 0 17.522a13.851 13.851 0 0 0 14.28.5c4.493-2.49 7.287-7.266 7.288-12.456 0-.216-.016-.432-.016-.649A10.019 10.019 0 0 0 24 2.337z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="twitter1" fill="#fff">
          <use xlinkHref="#twitter" />
        </mask>
        <use fill="#222321" fillRule="nonzero" xlinkHref="#twitter" />
        <path
          strokeWidth={0}
          fill="#000"
          d="M0-2h24v24H0z"
          mask="url(#twitter1)"
        />
      </g>
    </svg>
  );
};

Twitter.displayName = 'Twitter';
