import React from 'react';
import { IconButton, IconButtonSkins } from 'wix-ui-tpa';
import { shareGroupClick } from '@wix/bi-logger-groups/v2';
import { useBi } from '@wix/yoshi-flow-editor';

import { classes } from './ShareModal.st.css';
import { Block, BlockAlign } from '../Block';
import { FaceBook, LinkedIn, Twitter } from '../Icons';
import { ClipboardCopy } from './ClipboardCopy';
import { BIUserEntry } from '../../bi-logger/types';

export enum ShareDestination {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  LINK = 'link',
}

interface ShareComponentProps {
  shareUrl: string;
  onShare?(): void;
}

export const ShareComponent: React.FC<ShareComponentProps> = (props) => {
  const { shareUrl, onShare } = props;
  const bi = useBi();
  const share = async (dest: ShareDestination) => {
    await shareTo(dest, shareUrl);
    onShare && onShare();
  };

  const shareTo = (dest: ShareDestination, url: string) => {
    let shareBaseUrl;
    switch (dest) {
      case ShareDestination.FACEBOOK:
        shareBaseUrl = '//facebook.com/sharer/sharer.php?u=';
        break;
      case ShareDestination.TWITTER:
        shareBaseUrl = '//twitter.com/intent/tweet?url=';
        break;
      case ShareDestination.LINKEDIN:
        shareBaseUrl = 'https://www.linkedin.com/sharing/share-offsite/?url=';
        break;
      case ShareDestination.LINK:
        return;
      default:
        return;
    }

    bi.report(
      shareGroupClick({
        type: dest,
        origin: 'share_dialog_modal',
        userEntry: BIUserEntry.SITE,
      }),
    );

    return Promise.resolve(
      window.open(
        `${shareBaseUrl}${url}`,
        '_blank',
        'top=200, left=200, width=700, height=500',
      ),
    );
  };

  return (
    <Block
      tabIndex={-1}
      place={BlockAlign.center}
      justify={BlockAlign.center}
      className={classes.icons}
    >
      <IconButton
        className={classes.shareIcon}
        skin={IconButtonSkins.Full}
        icon={<FaceBook />}
        onClick={() => share(ShareDestination.FACEBOOK)}
        aria-label="facebook"
      />
      <IconButton
        className={classes.shareIcon}
        skin={IconButtonSkins.Full}
        icon={<Twitter />}
        onClick={() => share(ShareDestination.TWITTER)}
        aria-label="twitter"
      />
      <IconButton
        className={classes.shareIcon}
        skin={IconButtonSkins.Full}
        icon={<LinkedIn />}
        onClick={() => share(ShareDestination.LINKEDIN)}
        aria-label="linkedin"
      />
      <ClipboardCopy text={shareUrl} />
    </Block>
  );
};

ShareComponent.displayName = 'ShareComponent';
