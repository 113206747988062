import React from 'react';

export const Check: React.FC<React.SVGAttributes<SVGSVGElement>> = (props) => (
  <svg viewBox="0 0 24 24" width="24px" height="24px" {...props}>
    <g
      id="09-Icons-/-Check-/-Regular-Copy"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Check"
        transform="translate(5.000000, 5.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <polygon
          id="Path-2"
          points="13.3 0 14 0.7 5.3 10 0 5.8 0.6 5 5.2 8.7"
        />
      </g>
    </g>
  </svg>
);
