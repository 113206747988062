import React from 'react';

export const ThumbUpIcon: React.FC<React.SVGAttributes<SVGSVGElement>> = (
  props,
) => (
  <svg width="18px" height="16px" viewBox="0 0 16 16" {...props}>
    <g stroke="none" strokeWidth="0.4" fill="currentColor" fillRule="evenodd">
      <path d="m13.521,14.216c-0.101,0.454 -0.511,0.784 -0.977,0.784l-7.544,0l0,-7.365l1.912,-3.195c0.034,-0.058 0.056,-0.121 0.066,-0.188l0.456,-3.252l0.066,0c0.827,0 1.5,0.673 1.5,1.5l0,4c0,0.277 0.223,0.5 0.5,0.5l4.992,0c0.153,0 0.295,0.069 0.39,0.188c0.096,0.118 0.132,0.271 0.098,0.421l-1.459,6.607zm-9.521,0.784l-2.5,0c-0.276,0 -0.5,-0.224 -0.5,-0.5l0,-6c0,-0.275 0.224,-0.5 0.5,-0.5l2.5,0l0,7zm11.662,-8.439c-0.286,-0.357 -0.712,-0.561 -1.17,-0.561l-4.492,0l0,-3.5c0,-1.379 -1.122,-2.5 -2.5,-2.5l-0.5,0c-0.25,0 -0.461,0.184 -0.496,0.431l-0.503,3.582l-1.788,2.987l-2.713,0c-0.828,0 -1.5,0.673 -1.5,1.5l0,6c0,0.827 0.672,1.5 1.5,1.5l3,0c0.032,0 0.06,-0.013 0.09,-0.018l0,0.018l7.954,0c0.931,0 1.753,-0.66 1.954,-1.569l1.459,-6.607c0.099,-0.446 -0.009,-0.907 -0.295,-1.263l0,0z" />
    </g>
  </svg>
);
