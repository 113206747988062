import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { TextButtonPriority, Text, TextTypography, Spinner } from 'wix-ui-tpa';

import { BlackAndWhiteTextButton } from 'common/components/BlackAndWhiteTextButton';

import { classes } from '../PostEditorModal.st.css';

import { IPostEditorProps } from './types';

export function PostEditorMobile(props: IPostEditorProps) {
  const { disabled, isLoading, children, onCancel, onSubmit } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className={classes.mobileHeader}>
        <BlackAndWhiteTextButton
          onClick={onCancel}
          priority={TextButtonPriority.secondary}
        >
          {t('groups-web.discussion.new-post.mobile.back')}
        </BlackAndWhiteTextButton>
        <Text
          typography={TextTypography.smallTitle}
          className={classes.mobileTitle}
        >
          {t('groups-web.discussion.new-post.mobile.title')}
        </Text>
        <BlackAndWhiteTextButton
          onClick={onSubmit}
          priority={TextButtonPriority.primary}
          disabled={disabled || isLoading}
        >
          {isLoading ? (
            <Spinner diameter={20} />
          ) : (
            t('groups-web.discussion.new-post.mobile.post')
          )}
        </BlackAndWhiteTextButton>
      </div>
      <div className={classes.wrapper}>{children}</div>
    </>
  );
}
