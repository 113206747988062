import React from 'react';
import { IconToggleProps } from 'wix-ui-tpa';
import { take } from 'lodash';

import type { IUserReaction } from 'api/feed/types';
import { CardText } from 'common/components/Cards/CardText';

import { ReactedMembersTooltip } from '../../ReactedMembers';

import { ReactionComponent } from './ReactionComponent';

import { classes, st } from './Reaction.st.css';

export interface ReactionProps
  extends Pick<
    IconToggleProps,
    'checked' | 'label' | 'labelPlacement' | 'icon'
  > {
  className?: string;
  label: number | string;
  reactions: IUserReaction[];
  cta?: boolean;
  likeButton?: boolean;

  onClick?(): void;
}

export const Reaction: React.FC<ReactionProps> = (props) => {
  const { onClick, checked, reactions, cta, likeButton, className, ...rest } =
    props;

  const tooltip = React.useMemo(
    () =>
      take(reactions, 20)
        .map((reaction) => reaction.user.name)
        .join(', '),
    [JSON.stringify(reactions)],
  );

  const _className = st(
    classes.root,
    { checked, cta, likeButton } as any,
    className,
  );

  return (
    <ReactedMembersTooltip
      content={reactions.length ? <CardText title={tooltip} /> : null}
    >
      <ReactionComponent
        className={_className}
        checked={checked}
        onChange={onClick}
        {...rest}
      />
    </ReactedMembersTooltip>
  );
};
