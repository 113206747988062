import { IMAGE_TYPE, pluginImage } from 'wix-rich-content-plugin-image';
import { pluginImage as pluginImageViewer } from 'wix-rich-content-plugin-image/loadable/viewer';
import { pluginGiphy as pluginGiphyViewer } from 'wix-rich-content-plugin-giphy/loadable/viewer';
import { GIPHY_TYPE, pluginGiphy } from 'wix-rich-content-plugin-giphy';
import { pluginEmoji as pluginEmojiViewer } from 'wix-rich-content-plugin-emoji/viewer';
import { EMOJI_TYPE, pluginEmoji } from 'wix-rich-content-plugin-emoji';
import { MENTION_TYPE, pluginMentions } from 'wix-rich-content-plugin-mentions';
import { pluginMentions as pluginMentionsViewer } from 'wix-rich-content-plugin-mentions/loadable/viewer';
import { pluginLink, LINK_TYPE } from 'wix-rich-content-plugin-link';
import { pluginLink as pluginLinkViewer } from 'wix-rich-content-plugin-link/loadable/viewer';
import { Experiments } from '@wix/yoshi-flow-editor';
import type { PluginConfigSyncType } from '@wix/comments-ooi-client';
import {
  FILE_UPLOAD_TYPE,
  pluginFileUpload,
} from 'wix-rich-content-plugin-file-upload';
import { pluginFileUpload as pluginFileUploadViewer } from 'wix-rich-content-plugin-file-upload/loadable/viewer';
import {
  VIDEO_TYPE,
  pluginVideo,
  videoButtonsTypes,
} from 'wix-rich-content-plugin-video';
import { pluginVideo as pluginVideoViewer } from 'wix-rich-content-plugin-video/loadable/viewer';
import {
  pluginLinkButton,
  LINK_BUTTON_TYPE,
} from 'wix-rich-content-plugin-button';
import { pluginLinkButton as pluginLinkButtonViewer } from 'wix-rich-content-plugin-button/loadable/viewer';
import {
  pluginVerticalEmbed,
  VERTICAL_EMBED_TYPE,
  verticalEmbedProviders,
} from 'wix-rich-content-plugin-vertical-embed';
import { pluginVerticalEmbed as pluginVerticalEmbedViewer } from 'wix-rich-content-plugin-vertical-embed/loadable/viewer';
import {
  pluginLinkPreview,
  LinkPreviewProviders,
  LINK_PREVIEW_TYPE,
} from 'wix-rich-content-plugin-link-preview';
import { pluginLinkPreview as pluginLinkPreviewViewer } from 'wix-rich-content-plugin-link-preview/loadable/viewer';
import { pluginHtml, HTML_TYPE } from 'wix-rich-content-plugin-html';
import { pluginHtml as pluginHtmlViewer } from 'wix-rich-content-plugin-html/loadable/viewer';

import {
  pluginSpoiler,
  SpoilerEditorWrapper,
  SPOILER_TYPE,
} from 'wix-rich-content-plugin-spoiler';
import {
  pluginSpoiler as pluginSpoilerViewer,
  SpoilerViewerWrapper,
  initSpoilersContentState,
} from 'wix-rich-content-plugin-spoiler/viewer';

import {
  pluginTextColor,
  TEXT_COLOR_TYPE,
  pluginTextHighlight,
  TEXT_HIGHLIGHT_TYPE,
} from 'wix-rich-content-plugin-text-color';
import {
  pluginTextColor as pluginTextColorViewer,
  pluginTextHighlight as pluginTextHighlightViewer,
} from 'wix-rich-content-plugin-text-color/viewer';

import {
  pluginHeadings,
  HEADINGS_DROPDOWN_TYPE,
} from 'wix-rich-content-plugin-headings';

import {
  pluginLineSpacing,
  LINE_SPACING_TYPE,
} from 'wix-rich-content-plugin-line-spacing';
import { pluginLineSpacing as pluginLineSpacingViewer } from 'wix-rich-content-plugin-line-spacing/viewer';

export const pluginConfig = (
  experiments: Experiments,
  isMobile: boolean,
): PluginConfigSyncType => {
  const plugins = {
    [IMAGE_TYPE]: {
      editorModule: pluginImage,
      viewerModule: pluginImageViewer,
    },
    [GIPHY_TYPE]: {
      editorModule: pluginGiphy,
      viewerModule: pluginGiphyViewer,
    },
    [EMOJI_TYPE]: {
      editorModule: pluginEmoji,
      viewerModule: pluginEmojiViewer,
    },
    [LINK_TYPE]: { editorModule: pluginLink, viewerModule: pluginLinkViewer },
    [MENTION_TYPE]: {
      editorModule: pluginMentions,
      viewerModule: pluginMentionsViewer,
      config: {},
    },
    [FILE_UPLOAD_TYPE]: {
      editorModule: pluginFileUpload,
      viewerModule: pluginFileUploadViewer,
    },
    [VIDEO_TYPE]: {
      editorModule: pluginVideo,
      viewerModule: pluginVideoViewer,
      config: {
        exposeButtons: [videoButtonsTypes.soundCloud],
      },
    },
    [LINK_BUTTON_TYPE]: {
      editorModule: pluginLinkButton,
      viewerModule: pluginLinkButtonViewer,
    },
    [VERTICAL_EMBED_TYPE]: {
      editorModule: pluginVerticalEmbed,
      viewerModule: pluginVerticalEmbedViewer,
      config: {
        exposeEmbedButtons: [
          verticalEmbedProviders.event,
          verticalEmbedProviders.booking,
          verticalEmbedProviders.product,
        ],
      },
    },
    [LINK_PREVIEW_TYPE]: {
      editorModule: pluginLinkPreview,
      viewerModule: pluginLinkPreviewViewer,
      config: {
        exposeEmbedButtons: [
          LinkPreviewProviders.Instagram,
          LinkPreviewProviders.Twitter,
          LinkPreviewProviders.TikTok,
        ],
      },
    },
    [HTML_TYPE]: {
      editorModule: pluginHtml,
      viewerModule: pluginHtmlViewer,
    },
    [SPOILER_TYPE]: {
      editorModule: pluginSpoiler,
      viewerModule: pluginSpoilerViewer,
      config: {
        SpoilerEditorWrapper,
      },
      viewerConfig: {
        SpoilerViewerWrapper,
        initSpoilersContentState,
      },
    },
    [LINE_SPACING_TYPE]: {
      editorModule: pluginLineSpacing,
      viewerModule: pluginLineSpacingViewer,
    },
  } as PluginConfigSyncType;

  if (!isMobile) {
    plugins[TEXT_COLOR_TYPE] = {
      editorModule: pluginTextColor,
      viewerModule: pluginTextColorViewer,
    };
    plugins[TEXT_HIGHLIGHT_TYPE] = {
      editorModule: pluginTextHighlight,
      viewerModule: pluginTextHighlightViewer,
    };
    plugins[HEADINGS_DROPDOWN_TYPE] = {
      editorModule: pluginHeadings,
      viewerModule: pluginHeadings,
    };
  }
  return plugins;
};
