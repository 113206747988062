import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ButtonPriority } from 'wix-ui-tpa';

import { selectGroup } from 'store/groups/selectors';
import { selectDialog } from 'store/application/selectors';

import { Dialog } from 'common/components/Dialog/Dialog';
import { useController } from 'common/context/controller';

import { GroupMembershipButton } from '../GroupMembershipButton';
import { CANCEL_JOIN_REQUEST_DIALOG } from './dataHooks';

export function CancelJoinRequestDialog() {
  const { t } = useTranslation();
  const { application$, group$ } = useController();

  const dialog = useSelector(selectDialog('cancelJoinRequest'));
  const group = useSelector(selectGroup(dialog.groupId as string));

  if (!group) {
    return null;
  }

  return (
    <Dialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      data-hook={CANCEL_JOIN_REQUEST_DIALOG}
      title={t('groups-web.viewer.cancel-request-modal.title')}
      caption={t('groups-web.viewer.cancel-request-modal.body', {
        group: group.name,
      })}
      buttons={
        <GroupMembershipButton
          bw
          groupId={dialog.groupId as string}
          onClick={handleSubmit}
          priority={ButtonPriority.primary}
        >
          {t('groups-web.viewer.cancel-request-modal.cta')}
        </GroupMembershipButton>
      }
    />
  );

  function handleClose() {
    application$.closeDialog('cancelJoinRequest');
  }

  function handleSubmit() {
    group$.cancelRequest(dialog.groupId as string);
  }
}

CancelJoinRequestDialog.displayName = 'CancelJoinRequestDialog';
