import { servicesPlugin } from '@uirouter/core';

import type { UIRouter } from '@uirouter/core';

import {
  ControllerLocationService,
  WixLocationConfig,
  ILocationConfig,
} from './core';

export function viewerPlugin(location: ILocationConfig) {
  return function (router: UIRouter) {
    router.locationConfig = new WixLocationConfig(location);
    router.locationService = new ControllerLocationService(router);

    router.plugin(servicesPlugin);
    router.urlService.deferIntercept();

    return {
      name: 'WixUIRouterViewerPlugin',
      dispose(router: UIRouter) {},
    };
  };
}
