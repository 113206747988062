import React from 'react';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';

import { followMember } from '@wix/bi-logger-groups/v2';

import {
  ButtonProps,
  ButtonPriority,
  TextButtonPriority,
  Spinner,
} from 'wix-ui-tpa';

import { UISref } from 'common/router';
import { BIUserEntry } from 'common/bi-logger/types';
import { useController } from 'common/context/controller';

import { Button } from 'common/components/Button';
import { CustomTextButton } from 'common/components/CustomTextButton';
import { PrivateProfileHint } from 'common/components/PrivateProfileHint';

import {
  selectCurrentUser,
  selectFollowStatus,
} from 'store/application/selectors';

interface FollowButtonProps extends ButtonProps {
  bw?: boolean;
  memberId: string;
  isPrivate?: boolean;
  useTextButton?: boolean;

  // used for BI
  groupId?: string;
}

export const FollowButton: React.FC<FollowButtonProps> = (props) => {
  const { memberId, useTextButton, isPrivate, groupId, ...rest } = props;
  const { t } = useTranslation();

  const { application$ } = useController();
  const user = useSelector(selectCurrentUser);
  const status = useSelector(selectFollowStatus(memberId));

  const isCurrentUser = user.siteMemberId === memberId;
  const isFollowing = user.following.includes(memberId);

  const bi = useBi();

  const label = isFollowing
    ? t('groups-web.following')
    : t('groups-web.follow');

  if (isCurrentUser) {
    if (useTextButton) {
      return (
        <UISref state="members.profile" params={{ memberId }}>
          <CustomTextButton
            {...rest}
            as="a"
            priority={TextButtonPriority.primary}
          >
            {t('groups-web.my-profile')}
          </CustomTextButton>
        </UISref>
      );
    }

    return (
      <UISref state="members.profile" params={{ memberId }}>
        <Button {...rest} as="a" priority={ButtonPriority.secondary}>
          {t('groups-web.my-profile')}
        </Button>
      </UISref>
    );
  }

  if (useTextButton) {
    return (
      <PrivateProfileHint disabled={!isPrivate}>
        <CustomTextButton
          {...rest}
          onClick={handleClick}
          priority={TextButtonPriority.primary}
        >
          {status.loading ? <Spinner diameter={20} /> : label}
        </CustomTextButton>
      </PrivateProfileHint>
    );
  }

  return (
    <PrivateProfileHint disabled={!isPrivate}>
      <Button
        {...rest}
        onClick={handleClick}
        priority={
          isFollowing ? ButtonPriority.secondary : ButtonPriority.primary
        }
      >
        {status.loading ? <Spinner diameter={20} /> : label}
      </Button>
    </PrivateProfileHint>
  );

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    if (!user.loggedIn) {
      application$.promptLogin();
      return;
    }

    isFollowing
      ? application$.unfollow(memberId)
      : application$.follow(memberId);

    bi.report(
      followMember({
        group_id: groupId,
        site_member_id: memberId,
        origin: 'discussion_tab_link_clk',
        userEntry: BIUserEntry.SITE,
        action: isFollowing ? 'unfollow' : 'follow',
      }),
    );
  }
};

FollowButton.displayName = 'FollowButton';
