import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Info } from '@wix/wix-ui-icons-common/on-stage';
import { ButtonPriority, Text } from 'wix-ui-tpa';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { GroupMembershipButton } from '../../../../GroupMembership';

import { classes, st } from './SuggestedGroupLabel.st.css';

interface SuggestedGroupLabelProps extends TPAComponentProps {
  groupId: string;
}

export const SuggestedGroupLabel: React.FC<SuggestedGroupLabelProps> = (
  props,
) => {
  const { t } = useTranslation();

  return (
    <div className={st(classes.root, {}, props.className)}>
      <Text className={classes.label}>
        <Info />
        {t('groups-web.discussion.feed.suggested-group.post-label.info')}
      </Text>
      <div>
        <GroupMembershipButton
          groupId={props.groupId}
          biOrigin="public_feed"
          priority={ButtonPriority.secondary}
        />
      </div>
    </div>
  );
};
