import React, { useEffect } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { classes } from './ScrollLock.st.css';

export function useScrollBlock(blocked?: boolean, enabled = true) {
  const { isSSR } = useEnvironment();

  useEffect(() => {
    if (isSSR || !enabled) {
      return;
    }

    blocked ? lock() : unlock();

    return () => unlock();
  }, [isSSR, blocked, enabled]);

  function lock() {
    if (!document.body.classList.contains(classes.lock)) {
      document.body.classList.add(classes.lock);
    }
  }

  function unlock() {
    document.body.classList.remove(classes.lock);
  }
}
