import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from 'Groups/settingsParams';
import { LayoutType } from 'settings/consts';

export const useCurrentLayout = () => {
  const settings = useSettings();

  const currentLayout = settings.get(settingsParams.groupListLayout);
  const isSidebarLayoutActive = currentLayout === LayoutType.sideBar;

  return {
    currentLayout,
    isSidebarLayoutActive,
  };
};
