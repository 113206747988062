import React from 'react';
import { Text } from 'wix-ui-tpa';
import { useSelector } from 'react-redux';
import { Trans, useEnvironment } from '@wix/yoshi-flow-editor';

import {
  IDraftJSActivity,
  IFeedItemActivity,
  FeedItemActivityType,
  IGroupCoverChangedActivity,
  IUsersJoinedActivity,
} from 'api/feed/types';

import { selectGroupName, selectGroupSlug } from 'store/groups/selectors';
import { IGroup } from 'store/groups/types';

import { UsersJoined } from './UsersJoined';
import { st, classes } from './ActivityStatus.st.css';
import { ClickableGroupName } from './ClickableGroupName';

interface ActivityStatusProps {
  group?: IGroup;
  activity?: IFeedItemActivity;
}

export const ActivityStatus: React.FC<ActivityStatusProps> = ({
  activity,
  group,
}) => {
  const { isMobile } = useEnvironment();

  const groupName = useSelector(selectGroupName(group));
  const slug = useSelector(selectGroupSlug(group));

  const notClickablePart = (
    <Text className={st(classes.root, { mobile: isMobile })} />
  );

  const groupNameAsLink = (
    <ClickableGroupName slug={slug} groupId={group?.id!} />
  );

  if (activity) {
    switch (activity.activityType) {
      case FeedItemActivityType.DRAFT_JS: {
        if ((activity.data as IDraftJSActivity).activity === 'event_added') {
          return (
            <Trans
              i18nKey={
                groupName
                  ? 'groups-web.activity-post.event.added.with-group-name'
                  : 'groups-web.activity-post.event.added'
              }
              values={{ groupName }}
              components={[notClickablePart, groupNameAsLink]}
            />
          );
        }

        return null;
      }
      case FeedItemActivityType.GROUP_COVER_CHANGED: {
        let key = (activity.data as IGroupCoverChangedActivity).hasCoverBefore
          ? 'groups-web.activity-post.cover-image.updated'
          : 'groups-web.activity-post.cover-image.added';

        if (groupName) {
          key = (activity.data as IGroupCoverChangedActivity).hasCoverBefore
            ? 'groups-web.activity-post.cover-image.updated.with-group-name'
            : 'groups-web.activity-post.cover-image.added.with-group-name';
        }
        return (
          <Trans
            i18nKey={key}
            values={{ groupName }}
            components={[notClickablePart, groupNameAsLink]}
          />
        );
      }
      case FeedItemActivityType.ABOUT_GROUP_CHANGED: {
        return (
          <Trans
            i18nKey={
              groupName
                ? 'groups-web.activity-post.about.changed.with-group-name'
                : 'groups-web.activity-post.about.changed'
            }
            values={{ groupName }}
            components={[notClickablePart, groupNameAsLink]}
          />
        );
      }
      case FeedItemActivityType.USERS_JOINED: {
        return (
          <UsersJoined
            {...(activity.data as IUsersJoinedActivity)}
            group={group}
          />
        );
      }
      default:
        return null;
    }
  }

  if (groupName) {
    return (
      <Trans
        i18nKey="groups-web.activity-post.with-group-name"
        values={{ groupName }}
        components={[notClickablePart, groupNameAsLink]}
      />
    );
  }
  return null;
};
