import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { IFeedItem } from 'store/feed/types';
import settingsParams from 'Group/settingsParams';

import { Reactions } from './Reactions';
import { TotalComments } from './TotalComments';
import { ReactedMembers } from './ReactedMembers';

import { st, classes } from './ReactionsAndCommentsCounterRow.st.css';
import { useController } from 'common/context/controller';

import { useSelector } from 'react-redux';

import {
  selectHasReactions,
  selectReactedUsers,
  selectReactionsMap,
} from './selectors';

interface ReactionsAndCommentsCounterRowProps {
  item: IFeedItem;
  onCommentsCounterClick(): void;
}

export const ReactionsAndCommentsCounterRow: React.FC<
  ReactionsAndCommentsCounterRowProps
> = ({ item, onCommentsCounterClick }) => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const { application$, feed$ } = useController();

  const canReact = item.permissions?.canReact;
  const showReactions = settings.get(settingsParams.showReactions);
  const hasReactions = useSelector(selectHasReactions(item.feedItemId!));
  const reactionsMap = useSelector(selectReactionsMap(item.feedItemId!));
  const reactedUsers = useSelector(selectReactedUsers(item.feedItemId!));

  const groupId = item.applicationContext?.groupId as string;

  return (
    <div className={st(classes.root, { mobile: isMobile, hasReactions })}>
      {showReactions && (
        <div className={classes.reactions}>
          <Reactions
            reactions={reactionsMap}
            onReact={handleReactClick}
            onUnreact={handleUnreactClick}
          />

          {!hasReactions ? (
            <TotalComments item={item} onClick={onCommentsCounterClick} />
          ) : null}
        </div>
      )}

      {(hasReactions || !showReactions) && (
        <div className={classes.meta}>
          <ReactedMembers
            users={reactedUsers}
            reactionsMap={reactionsMap}
            reactions={item.reactions}
          />
          <TotalComments item={item} onClick={onCommentsCounterClick} />
        </div>
      )}
    </div>
  );

  function handleReactClick(reactionCode: string) {
    if (canReact) {
      feed$.react(groupId, item.feedItemId!, reactionCode);
    } else {
      application$.showDialog({
        groupId,
        dialog: 'joinGroup',
      });
    }
  }

  function handleUnreactClick(reactionCode: string) {
    if (canReact) {
      feed$.unreact(groupId, item.feedItemId!, reactionCode);
    } else {
      application$.showDialog({
        groupId,
        dialog: 'joinGroup',
      });
    }
  }
};
