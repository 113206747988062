import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Spinner, ButtonPriority } from 'wix-ui-tpa';

import { selectFeedItemStatus } from 'store/feed/selectors';

import { Modal } from 'common/components/Modal/Modal';
import { Button } from 'common/components/Button/Button';

interface DeletePostModalProps {
  feedItemId: string;
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
}

export const DeletePostModal: React.FC<DeletePostModalProps> = (props) => {
  const { isOpen, onClose, onSubmit } = props;

  const { t } = useTranslation();
  const status = useSelector(selectFeedItemStatus(props.feedItemId));

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header
        title={t('groups-web.discussion.delete-post-popup.title')}
        subtitle={t('groups-web.discussion.delete-post-popup.subtitle')}
      />
      <Modal.Buttons>
        <Button bw priority={ButtonPriority.secondary} onClick={onClose}>
          {t('groups-web.discussion.delete-post-popup.cancel')}
        </Button>
        <Button
          bw
          onClick={onSubmit}
          disabled={status.remove.pending}
          priority={ButtonPriority.primary}
        >
          {status.remove.pending ? (
            <Spinner diameter={20} />
          ) : (
            t('groups-web.discussion.delete-post-popup.delete')
          )}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

DeletePostModal.displayName = 'DeletePostModal';
