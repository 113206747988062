import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  IconToggle,
  IconToggleProps,
  IconToggleLabelPlacement,
} from 'wix-ui-tpa';
// import { ActionType } from 'Group/Widget/UserStatus/ActionType';
// import { useUserActions } from 'Group/Widget/UserStatus/useUserActions';

import { classes } from './Reaction.st.css';

interface Props extends IconToggleProps {}

export const ReactionComponent: React.FC<Props> = ({
  onChange,
  labelPlacement = IconToggleLabelPlacement.END,
  checked = false,
  ...rest
}) => {
  const { t } = useTranslation();
  // const { initAction } = useUserActions();
  // async function react(e: any) {
  //   try {
  //     await initAction(ActionType.CHECK_GROUP_MEMBERSHIP);
  //     onChange?.(e);
  //   } catch (_) {}
  // }

  const ariaLabel = checked
    ? t('groups-web.a11y.reactions.unreact')
    : t('groups-web.a11y.reactions.react');
  return (
    <label aria-label={ariaLabel} className={classes.label}>
      <IconToggle
        {...rest}
        animation
        checked={checked}
        onChange={onChange}
        labelPlacement={labelPlacement}
      />
    </label>
  );
};
