import { IStyleParam, StyleParamType, wixColorParam } from '@wix/tpa-settings';

export type IAppearanceStylesParams = {
  cardBorderCornerRadius: IStyleParam<StyleParamType.Number>;
  applyShadow: IStyleParam<StyleParamType.Boolean>;
  shadowAngle: IStyleParam<StyleParamType.Number>;
  shadowDistance: IStyleParam<StyleParamType.Number>;
  shadowBlur: IStyleParam<StyleParamType.Number>;
  shadowColor: IStyleParam<StyleParamType.Color>;
};

export const appearanceStylesParams: IAppearanceStylesParams = {
  cardBorderCornerRadius: {
    key: 'cardBorderCornerRadius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  applyShadow: {
    key: 'applyShadow',
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  shadowAngle: {
    key: 'shadowAngle',
    type: StyleParamType.Number,
    getDefaultValue: () => 180,
  },
  shadowDistance: {
    key: 'shadowDistance',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  shadowBlur: {
    key: 'shadowBlur',
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  shadowColor: {
    key: 'shadowColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.1),
  },
};
