import React from 'react';

export const AddIcon: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <g>
          <g>
            <g>
              <path
                d="M12.5 6v5.5H18v1h-5.5V18h-1v-5.5H6v-1h5.5V6h1z"
                transform="translate(-1228 -391) translate(1216 383) translate(12.857) translate(0 8)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
